
import Btn from '@/common/components/Btn.vue';
import Modal from '@/common/components/Modal.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import { computed, defineComponent, PropType, ref } from 'vue';
import PIS from '@/common/api/PIS';
import { Instance } from '@/catalogs/api/Instance';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { AliasEditActionData } from '@/catalogs/api/runtime/CatalogActionData';
import { attrValue } from '@/common/helpers/productAttributes';
import {
  AddAliasesCommand,
  AliasesReloadCommand,
  DeleteAliasesCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import {
  ActionInfo,
  ActionResult,
  Alias,
  AliasActionResult,
} from '@/common/services/swagger/index.defs';

export default defineComponent({
  components: {
    Btn,
    Modal,
    FormInputClearable,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    data: { type: Object as PropType<AliasEditActionData>, required: true },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const show = ref<boolean>(true);
    const productId = computed<string | undefined>(
      () => props.data.alias.alternateProductId || props.data.alias.productId,
    );
    const toBeAddedAlias = ref<string>('');
    const currentMessage = ref<string>('');
    const currentWarnings = ref<ActionInfo[]>([]);
    const currentErrors = ref<ActionInfo[]>([]);
    const aliases: Alias[] = [...(props.data.alias.aliases || [])];
    const isFormValid = computed<boolean>(() => toBeAddedAlias.value.length > 2);
    const resetErrorsAndWarnings = () => {
      currentMessage.value = '';
      currentWarnings.value = [];
      currentErrors.value = [];
    };
    const setErrorsAndWarnings = (actionResult: ActionResult | undefined) => {
      if (actionResult) {
        if (actionResult.message) {
          currentMessage.value = actionResult.message;
        }
        if (actionResult.warnings) {
          currentWarnings.value = [...actionResult.warnings];
        }
        if (actionResult.errors) {
          currentErrors.value = [...actionResult.errors];
        }
      }
    };
    const onAddAlias = async (aliasValue: string): Promise<unknown> => {
      resetErrorsAndWarnings();
      if (!productId.value) {
        return;
      }
      try {
        const actionResult: AliasActionResult | undefined = await instance.execute(
          new AddAliasesCommand({
            doNotHandleErrors: true,
            onSuccessMessage: '',
            aliases: [{ productId: productId.value, value: aliasValue }],
          }),
        );
        setErrorsAndWarnings(actionResult);
        if (actionResult && actionResult.success) {
          aliases.push(...(actionResult.aliases || []));
          toBeAddedAlias.value = '';
        }
      } catch (error) {
        // Ignore Error
      }
    };
    const onDeleteAlias = async (toBeDeleted: Alias) => {
      resetErrorsAndWarnings();
      try {
        const actionResult: ActionResult | undefined = await instance.execute(
          new DeleteAliasesCommand({
            onSuccessMessage: '',
            doNotHandleErrors: true,
            aliases: [{ id: toBeDeleted.id }],
          }),
        );
        setErrorsAndWarnings(actionResult);
        if (actionResult && actionResult.success) {
          const index: number = aliases.indexOf(toBeDeleted);
          if (index !== -1) {
            aliases.splice(index, 1);
          }
        }
      } catch (error) {
        // Ignore Error
      }
    };
    const onCurrentAliasUpdate = (value: string) => (toBeAddedAlias.value = value);
    const onModalShowUpdate = async (shown: boolean) => {
      try {
        show.value = shown;
        emit('update:show', shown);
        if (shown === false) {
          await instance.execute(new AliasesReloadCommand());
        }
      } catch (error) {
        //Ignore Error
      }
    };
    return {
      show,
      aliases,
      isFormValid,
      toBeAddedAlias,
      currentErrors,
      currentMessage,
      currentWarnings,
      onModalShowUpdate,
      attrValue,
      onAddAlias,
      onDeleteAlias,
      onCurrentAliasUpdate,
    };
  },
});
