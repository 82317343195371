/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export interface ActionInfo {
  /** Action error object identifier */
  id?: string;

  /** Action error message */
  message?: string;
}

export interface ActionResult {
  /** Action success */
  success: boolean;

  /** Action message */
  message?: string;

  /** Action errors */
  errors?: ActionInfo[];

  /** Action warnings */
  warnings?: ActionInfo[];
}

export interface Alias {
  /** Alias unique identifier */
  id: string;

  /** Alias value */
  value: string;

  /** Product id */
  productId: string;

  /** Alias modified date */
  dateModified: Date;

  /** Alias created date */
  dateCreated: Date;
}

export interface AliasActionResult {
  /** Aliases created \/ updated */
  aliases?: Alias[];

  /** Action success */
  success: boolean;

  /** Action message */
  message?: string;

  /** Action errors */
  errors?: ActionInfo[];

  /** Action warnings */
  warnings?: ActionInfo[];
}

export interface AliasAddAction {
  /** Aliases to add */
  aliases: AliasAddItem[];

  /**  */
  appSettings: CatalogAppSettings;
}

export interface AliasAddItem {
  /** Product id */
  productId: string;

  /** Alias value */
  value: string;
}

export interface AliasDeleteAction {
  /** Aliases to delete */
  aliases: AliasDeleteItem[];

  /**  */
  appSettings: CatalogAppSettings;
}

export interface AliasDeleteItem {
  /** Alias id */
  id: string;
}

export interface AliasExportAction {
  /**  */
  appSettings: CatalogAppSettings;
}

export interface AliasImportAction {
  /** File content in Base64 */
  fileContentBase64: string;

  /** File name */
  fileName: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface AliasItem {
  /** Editable */
  editable: boolean;

  /** Alias unique identifier */
  id: string;

  /** Alias value */
  value: string;

  /** Product id */
  productId: string;

  /** Alias modified date */
  dateModified: Date;

  /** Alias created date */
  dateCreated: Date;
}

export interface AliasListQuery {
  /**  */
  search: ListSearchSettings;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface AliasListSearchResult {
  /** List of aliases */
  items?: AliasItem[];
}

export interface AliasSettings {
  /** This field is the local ID which combined with the domain for the organization as defined and submitted by frontend
system which enables customer alias display and search in the controls */
  orgIdLocal?: string;

  /** This field is the full global ID (domain|orgIdLocal) for the organization as defined and submitted by frontend
system which enables customer alias display and search in the controls */
  orgIdGlobal?: string;

  /** This field is the domain for the Local ID for the organization as defined and submitted by frontend system which
enables customer alias display and search in the controls */
  domain?: string;

  /** List of customers which enables customer alias display and search in the controls */
  customers?: Customer[];
}

export interface AppSettings {
  /** Country code.
The Country Market Availability and Country Market Availability Type attribute values associated with a product
instance determine the countries where a product will be seen.
Filters the results to only the product instances available for the designated Country */
  countryCode?: string;

  /**  */
  treeType?: TreeTypeEnum;

  /** Displays internal only attributes for internal ABB users.
Requires application authorizations. */
  internalUser?: boolean;

  /**  */
  aliases?: AliasSettings;

  /**  */
  filters?: ApplicationFilters;

  /**  */
  appSpecific?: AppSpecificSettings;

  /**  */
  client?: ClientInfo;

  /** AGM List Code
Allows systems to filter search results based on a predefined AGM(Attribute Group Management)list provided by the
product owner.
The AGM list defines attributes and values to-pre filter the search results
When an AGM Predefined list is selected from the drop down box only predefined attributes and predefined values
should show up
Allows systems to filter search results based on a predefined AGM(Attribute Group Management)list provided by the
product owner.
The AGM list defines attributes and values to-pre filter the search results.
When an AGM Predefined list is selected from the drop down box only predefined attributes and predefined values
should show up
Allows systems to filter search results based on a predefined AGM(Attribute Group Management)list provided by the
product owner.
The AGM list defines attributes and values to-pre filter the search results.
When an AGM Predefined list is selected from the drop down box only predefined attributes and predefined values
should show up */
  agmListCode?: string;

  /** Allows systems to identify users as anonymous which triggers the show\/hide of product images and where used
information on item detail pages.
Configurable per business. */
  anonymousUser?: boolean;

  /** Application code.
The Publishers and Publishers Type attribute values associated with a product instance determine the availability
in front end application(s).
Property required. */
  appCode: string;

  /** Language code.
Web labels, attribute names, and attribute values will be presented in the requested translated language (if the
translations exist).
Default value: "en" */
  langCode?: string;

  /** Enables product detail relationship filtering. */
  productRelationshipFiltering?: boolean;
}

export interface AppSpecificSettings {
  /** Providing a customer enables additional logic for "customer specific products" and "product available by customer"
features. */
  customerId?: string;

  /** Input is an existing Associated Group value in ABB Products.
Identifies groups of customers
Providing an Associated Group enables additional logic for customer specific products and products available by
customer features "Associated Groups" is an attribute in ABB Products.
Filters the results to only products or parts that have the designated Associated Group */
  associatedGroups?: string[];

  /** Genericize attribute names.
Removes leading 'ABB' from attribute descriptions */
  genericizeAttName?: boolean;

  /** Sales Unit Organization
Providing a value enables Local Order Code search and display based on data loaded by individual sales units for
the attributes Local Sales Organization and Local Order Code.
When Sales Unit Code is selected and a product instance search is made, if there is a Local Order Code available it
will be returned in the product preview */
  salesUnitCode?: string;

  /** If "Front End Filter" values are provided by requesting systems, PIS will filter out all products where the filter
value exists.
This is in addition to all other filters submitted or currently in place for PIS.
Front End Filter for a product instance is defined by value loaded for the attribute “Front End Filter”. */
  frontEndFilter?: string[];

  /** Depth of the classification tree
Used only in search method */
  treeDepth?: number;

  /** All Replacements.
Retrieves all replacement products available for items in search results.
When this options is disabled only replacements are returned for Obsolete or Inactive products.
Used only in Search method */
  allReplacements?: boolean;

  /** Selling Unit For Local Products
Filters the results to include normal results plus local products provided by the sales unit "Local Product" is an
attribute in ABB Products. Possible values are Yes or No
Product instances where Local Product = Yes are not shown under normal search results */
  sellingUnitForLocalProducts?: string[];

  /** Table row matching.
Enables product Table data matching based on rows instead of entire set. */
  tableRowMatching?: boolean;

  /** Related products.
Returns RelatedProducts on ProductDetail. */
  relatedProducts?: boolean;

  /** Standardization Body.
If pre-defined certification agency is “IEC” then hide attributes from other AGM list (UL). */
  standardizationBody?: string[];

  /** Providing a customers enables additional logic for "customer specific products" and "product available by customer"
features. */
  customers?: Customer[];
}

export interface ApplicationFilters {
  /** Detail Group code
Filters the results to display only products or parts belonging to the Detail Groups submitted.
The Detail Group for product instances is defined by the value loaded for the attribute "ABB Detail Group”.
Ex: 4121, 4131, 4421, etc… */
  buId?: string[];

  /** Producing Unit
Filters the results to display only products or parts belonging to the Producing Unit submitted.
The Producing Unit for a product instance is defined by the value loaded for the attribute “Producing Unit”.
Ex: FIDRI, SEMOT, SEROP, etc… */
  puId?: string[];

  /** Sales Organization
Filters the results to display only products or parts belonging to the Sales Organization submitted.
Sales Organizations for product instances are defined by values loaded for the attribute Local Sales
Organization_xxxxx.
Ex: SUDESTO, SUNOCRL, etc… */
  suId?: string[];

  /** Node CID(s) (one or multiple) from one of the ABB Offering Trees
Filters the results to only product instances classified below the CID(s) provided. */
  restrictedCids?: string[];

  /** Sales ERP
Allows front-end systems to filter on products currently in scope for the export from ABB Products to the Sales
ERP. The filter is applied based on the value loaded for the Sales ERP attribute.
Null - no changes
True - will show only products where SalesErp = Y
False - products where SalesErp is not attached or = N will be filtered out of results. */
  salesErp?: boolean;

  /** Data Owners
Filters the results to include only products or parts marked "Y" for the attribute “Supplier Specific Detail” for
the specified Data Owner.
These Products or Parts are not displayed for customers, but only for supplier users (Data Owners) when submitted.
Products or parts marked with “Supplier Specific Detail” value of "YES" should not be seen in results unless Data
Owner is entered in this field */
  dataOwners?: string[];

  /** Product Identifiers
Filter the results to include only products with provided identifiers. */
  productIdentifiers?: string[];

  /** Inclusion criteria */
  inclusionCriteria?: Criteria[];

  /** Exclusion criteria */
  exclusionCriteria?: Criteria[];

  /** Sort by provided ProductIdentifiers list */
  sortByProductIdentifiers?: boolean;
}

export interface ApplicationUseSettings {
  /** Disable application use check.
When set to true the default logic for Application Use and Application Use Type user fields is ignored. */
  disable?: boolean;

  /** List of exception cids */
  exceptionCids?: string[];
}

export interface AttributeGroup {
  /** Attribute group code */
  code: string;

  /** Attribute group description */
  description: string;

  /** Attribute group visibility */
  visible: boolean;

  /** Attribute group spare part supplier code */
  sparePartSupplier?: string;

  /** Dictionary of product attributes
Key is attribute code */
  attributes: {[key: string]: ProductAttribute};

  /** Linked relationship */
  relationshipCode?: string;
}

export interface AttributeGroupSearchResult {
  /** Attribute group items */
  items?: AttributeGroup[];
}

export interface AttributeValue {
  /** Attribute value. Formatted for presentation.
Supports translations. In case of enumeration value label translation is returned. In case of multidimensional */
  text: string;

  /** Enumeration value code
Populated only for attributes of type Enumeration */
  enumerationValueCode?: string;

  /** Parsed attribute value with valid product identifiers marked inside double curly braces "{{ProductId}}".
Example: Log \/ Set incl. 1 pc {{3BSE007949R1}} DSAI 146 */
  interactiveText?: string;

  /**  */
  link?: Link;
}

export interface AuthorizeCatalogUser {
  /**  */
  catalogUser: CatalogUser;

  /** Secret */
  secret: string;
}

export interface AutocompleteCategoryItem {
  /** Category full path. */
  breadcrumbs?: BreadcrumbItem[];

  /** Category images. */
  images?: Image[];

  /** Category keywords. */
  keywords?: string[];

  /**  */
  match?: BreadcrumbItem;
}

export interface AutocompleteItem {
  /** Product id */
  productId: string;

  /** Product domain */
  productIdDomain?: string;

  /** Dictionary of product attributes
Key is attribute code */
  attributes: {[key: string]: ProductAttribute};

  /** Product images. */
  images?: Image[];
}

export interface AutocompleteQuery {
  /**  */
  appSettings: AppSettings;

  /**  */
  pager?: PagerSettings;

  /**  */
  search: SearchSettings;

  /** Enables category search suggestions */
  categorySearch?: boolean;

  /** Enables category keyword search suggestions. Requires CategorySearch set to true. */
  categoryKeywordSearch?: boolean;

  /** Enables auto category navigation if all results are in one category */
  autoCategoryNavigation?: boolean;

  /** Enables fetching images for products */
  fetchProductImages?: boolean;

  /** Enables fetching images for categories */
  fetchCategoryImages?: boolean;
}

export interface AutocompleteSearchResult {
  /** Root category total count of product matches */
  rootTotalCount: number;

  /** Total count of product matches */
  totalCount: number;

  /** Root category without filters total count of product matches. Value only provided when TotalCount and RootTotalCount is equal 0. */
  noFiltersTotalCount: number;

  /** List of alphabetically sorted product matches */
  matches?: string[];

  /** List of alphabetically sorted product items.
This list contains more information about found products. */
  items?: AutocompleteItem[];

  /** List of categories */
  categories?: AutocompleteCategoryItem[];

  /**  */
  autoCategoryNavigationCategory?: BreadcrumbItem;
}

export interface BreadcrumbItem {
  /** CID of the breadcrumb */
  cid: string;

  /** Name of the breadcrumb */
  name: string;

  /** Determines if the current breadcrumb should be navigable. */
  inactive: boolean;
}

export interface BreadcrumbsSearchResult {
  /** List of Pis.Products.Models.Dto.Breadcrumbs.BreadcrumbItem items */
  items?: BreadcrumbItem[];
}

export interface CacheSetAction {
  /** Cache key */
  key: string;

  /** Cache value */
  value: string;
}

export interface Catalog {
  /** Catalog unique identifier */
  id: string;

  /** Catalog code */
  code: string;

  /**  */
  type: CatalogTypeEnum;

  /**  */
  owner: Owner;

  /** Catalog description */
  description: string;

  /** Catalog modified date */
  dateModified: Date;

  /** Catalog created date */
  dateCreated: Date;
}

export interface CatalogAction {
  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogActionResult {
  /**  */
  catalog?: Catalog;

  /** Action success */
  success: boolean;

  /** Action message */
  message?: string;

  /** Action errors */
  errors?: ActionInfo[];

  /** Action warnings */
  warnings?: ActionInfo[];
}

export interface CatalogAddAction {
  /** Catalog code */
  code: string;

  /**  */
  type: CatalogTypeEnum;

  /** Catalog description */
  description: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogAppSettings {
  /**  */
  client?: ClientInfo;

  /**  */
  catalogType?: CatalogTypeEnum;

  /** Application code.
The Publishers and Publishers Type attribute values associated with a product instance determine the availability
in front end application(s).
Property required. */
  appCode: string;

  /** Language code.
Web labels, attribute names, and attribute values will be presented in the requested translated language (if the
translations exist).
Default value: "en" */
  langCode?: string;

  /**  */
  appSpecific?: CatalogAppSpecificSettings;
}

export interface CatalogAppSpecificSettings {
  /** Enables configured item aliases. When enabled all configured items will have alias view, search and editing features. */
  configuredItemAliasesEnabled?: boolean;
}

export interface CatalogAutocompleteQuery {
  /**  */
  search: CatalogSearchSettings;

  /**  */
  appSettings: CatalogAppSettings;

  /**  */
  pager?: PagerSettings;
}

export interface CatalogAutocompleteSearchResult {
  /** Total hit count of matches */
  totalCount: number;

  /** List of alphabetically sorted matches */
  matches?: string[];
}

export interface CatalogCopyAction {
  /** Source catalog unique identifier */
  sourceCatalogId: string;

  /** Target catalog unique identifier */
  targetCatalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogCopyToNewAction {
  /** Source catalog unique identifier */
  sourceCatalogId: string;

  /** New catalog code */
  code: string;

  /**  */
  type: CatalogTypeEnum;

  /** New catalog description */
  description: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogDetail {
  /** Editable */
  editable: boolean;

  /** Quantities */
  quantities?: QuantityItem[];

  /** Catalog unique identifier */
  id: string;

  /** Catalog code */
  code: string;

  /**  */
  type: CatalogTypeEnum;

  /**  */
  owner: Owner;

  /** Catalog description */
  description: string;

  /** Catalog modified date */
  dateModified: Date;

  /** Catalog created date */
  dateCreated: Date;
}

export interface CatalogExportAction {
  /**  */
  priceSettings?: CatalogExportPriceSettings;

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogExportActionResult {
  /**  */
  type?: CatalogExportResultTypeEnum;

  /** Download guid */
  guid?: string;

  /** Action success */
  success: boolean;

  /** Action message */
  message?: string;

  /** Action errors */
  errors?: ActionInfo[];

  /** Action warnings */
  warnings?: ActionInfo[];
}

export interface CatalogExportPriceSettings {
  /** Price export enabled */
  enabled: boolean;

  /** List of emails */
  emails: string[];
}

export interface CatalogGetQuery {
  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogImportAction {
  /** File content in Base64 */
  fileContentBase64: string;

  /** File name */
  fileName: string;

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogItem {
  /** Default catalog */
  default: boolean;

  /** Editable */
  editable: boolean;

  /** Total count of products in catalog */
  productsTotalCount: number;

  /** Catalog unique identifier */
  id: string;

  /** Catalog code */
  code: string;

  /**  */
  type: CatalogTypeEnum;

  /**  */
  owner: Owner;

  /** Catalog description */
  description: string;

  /** Catalog modified date */
  dateModified: Date;

  /** Catalog created date */
  dateCreated: Date;
}

export interface CatalogOption {
  /**  */
  type?: CatalogTypeEnum;
}

export interface CatalogOptionsQuery {
  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogOptionsResult {
  /** List of catalog options */
  items?: CatalogOption[];

  /** Default user email for catalog export with prices */
  email?: string;

  /** Indicates whenever prices are available for current customer System Id */
  pricesAvailable?: boolean;

  /** Indicates whenever aliases are editable for current customer System Id */
  aliasesEditable?: boolean;
}

export interface CatalogProductAddAction {
  /** Products to add */
  products: CatalogProductAddItem[];

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogProductAddItem {
  /** Product id */
  productId: string;

  /**  */
  productType: CatalogProductTypeEnum;

  /** Description */
  description?: string;

  /** Customer classification */
  customerClassification?: string;

  /** Base product id */
  baseProductId?: string;

  /** Quantity */
  quantity: number;

  /** Unit of measure */
  unitOfMeasure: string;

  /** Store additional information used by front end application */
  store?: string;

  /** Custom fields to store additional information in key value pair format used by front end application */
  customFields?: CustomFieldItem[];

  /** Alternate Product id */
  alternateProductId?: string;
}

export interface CatalogProductAutocompleteQuery {
  /**  */
  search: CatalogProductSearchSettings;

  /**  */
  catalogSearch?: CatalogSearchSettings;

  /**  */
  appSettings: CatalogAppSettings;

  /**  */
  pager?: PagerSettings;
}

export interface CatalogProductCountQuery {
  /**  */
  search: CatalogProductSearchSettings;

  /**  */
  catalogSearch?: CatalogSearchSettings;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogProductDeleteAction {
  /** Products to delete */
  products: CatalogProductDeleteItem[];

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogProductDeleteItem {
  /** Product id */
  productId: string;
}

export interface CatalogProductFilterSearchSettings {
  /** List of filter attribute codes */
  attributeCodes: string[];

  /** CID of the tree node */
  cid?: string;

  /** Catalog id */
  catalogId?: string;

  /** Search text */
  searchText?: string;

  /**  */
  searchType: CatalogProductSearchTypeEnum;

  /**  */
  treeType?: TreeTypeEnum;

  /**  */
  aliases?: AliasesEnum;

  /** List of Pis.Products.Models.Actions.IFilterCommonSearchSettings.FilterSettings for which data will be filtered */
  filterSettings?: FilterSettings[];
}

export interface CatalogProductFiltersQuery {
  /**  */
  search: CatalogProductFilterSearchSettings;

  /**  */
  catalogSearch?: CatalogSearchSettings;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogProductItem {
  /** List of aliases */
  aliases?: AliasItem[];

  /** Specifies whenever product is inactive and navigation for details and preview should be disabled. */
  inactive: boolean;

  /** Catalogs */
  catalogs?: CatalogDetail[];

  /**  */
  productType: CatalogProductTypeEnum;

  /** Product images */
  images?: Image[];

  /**  */
  baseProduct?: ProductItem;

  /** Customer classification */
  customerClassification?: string;

  /** Product modified date<br \/>
In Alias mode, property contains latest DateModified from all product aliases. */
  dateModified?: Date;

  /** Product created date<br \/>
In Alias mode, property contains latest DateCreated from all product aliases. */
  dateCreated?: Date;

  /** Custom fields to store additional information in key value pair format used by front end application */
  customFields?: CustomFieldItem[];

  /** Specifies whenever product was imported */
  imported?: boolean;

  /** Import order (whenever product was imported) */
  importOrder?: number;

  /** Alternate Product id */
  alternateProductId?: string;

  /** Product id */
  productId: string;

  /** Product domain */
  productIdDomain?: string;

  /** Dictionary of product attributes
Key is attribute code */
  attributes: {[key: string]: ProductAttribute};

  /** Exact Match is true when search text matches any of product identifiers. */
  exactMatch: boolean;

  /** Identifies whether product is spare part. */
  sparePart: boolean;

  /**  */
  classifications?: ProductItemClassifications;
}

export interface CatalogProductSearchQuery {
  /** Search data types to retrieve */
  dataTypes: CatalogProductSearchDataTypeEnum[];

  /** List of attribute codes for which retrieve values (this list replaces default attributes).
Attribute code can use "*" pattern to match more than one attributes. For example: "LeadTime_*" */
  attributeCodes?: string[];

  /**  */
  search?: CatalogProductSearchSettings;

  /**  */
  catalogSearch?: CatalogSearchSettings;

  /**  */
  sort?: CatalogProductSortSettings;

  /**  */
  appSettings: CatalogAppSettings;

  /**  */
  pager?: PagerSettings;
}

export interface CatalogProductSearchResult {
  /**  */
  productClassifications?: ClassificationsSearchResult;

  /**  */
  partClassifications?: ClassificationsSearchResult;

  /**  */
  products?: CatalogProductsSearchResult;

  /**  */
  filters?: FilterSearchResult;

  /**  */
  breadcrumbs?: BreadcrumbsSearchResult;
}

export interface CatalogProductSearchSettings {
  /** CID of the tree node */
  cid?: string;

  /** Catalog id */
  catalogId?: string;

  /** Search text */
  searchText?: string;

  /**  */
  searchType: CatalogProductSearchTypeEnum;

  /**  */
  treeType?: TreeTypeEnum;

  /**  */
  aliases?: AliasesEnum;

  /** List of Pis.Products.Models.Actions.IFilterCommonSearchSettings.FilterSettings for which data will be filtered */
  filterSettings?: FilterSettings[];
}

export interface CatalogProductSortSettings {
  /**  */
  field: CatalogProductSortFieldEnum;

  /**  */
  direction: SortDirectionEnum;
}

export interface CatalogProductUpdateAction {
  /** Products to update */
  products: CatalogProductUpdateItem[];

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogProductUpdateItem {
  /** Product id */
  productId: string;

  /** Description */
  description?: string;

  /** Customer classification */
  customerClassification?: string;

  /** Base product id */
  baseProductId?: string;

  /** Quantity */
  quantity: number;

  /** Unit of measure */
  unitOfMeasure: string;

  /** Store additional information used by front end application */
  store?: string;

  /** Custom fields to store additional information in key value pair format used by front end application */
  customFields?: CustomFieldItem[];

  /** Alternate Product id */
  alternateProductId?: string;
}

export interface CatalogProductsSearchResult {
  /** List of product item */
  items?: CatalogProductItem[];

  /** Total items count */
  totalCount: number;
}

export interface CatalogSearchQuery {
  /**  */
  search?: CatalogSearchSettings;

  /**  */
  sort?: CatalogSortSettings;

  /**  */
  appSettings: CatalogAppSettings;

  /**  */
  pager?: PagerSettings;
}

export interface CatalogSearchResult {
  /** List of catalog items */
  items?: CatalogItem[];

  /** Total catalogs count */
  totalCount: number;
}

export interface CatalogSearchSettings {
  /** Created within last days */
  createdWithinLastDays?: number;

  /** Search text */
  searchText?: string;

  /**  */
  searchType: CatalogSearchTypeEnum;
}

export interface CatalogSortSettings {
  /**  */
  field: CatalogSortFieldEnum;

  /**  */
  direction: SortDirectionEnum;
}

export interface CatalogUpdateAction {
  /** Catalog description */
  description?: string;

  /** Default catalog */
  default?: boolean;

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface CatalogUser {
  /** Company system id */
  systemId?: string;

  /** Company sales unit */
  salesUnit?: string;

  /** Company customer id */
  customerId?: string;

  /** Personal user id */
  userId: string;

  /** Display host codes.
Host code catalogs to select in current view. All host code catalogs are read only unless the current user is owner of the catalog. */
  displayHostCodes?: string[];

  /** Host code.
If provided catalogs will be created as shared host code catalog. */
  hostCode?: string;

  /** Pricing currency. Required for pricing export. */
  pricingCurrency?: string;

  /** Pricing shipment Required for pricing export. */
  pricingShipment?: string;

  /** Pricing country Required for pricing export. */
  pricingCountry?: string;

  /** Pricing system id */
  pricingSystemId?: string;

  /** Pricing customer id */
  pricingCustomerId?: string;

  /** Pricing sales unit */
  pricingSalesUnit?: string;
}

export interface ClassificationAdditionalInfo {
  /**  */
  type?: ClassificationAdditionalInfoTypeEnum;

  /** Value */
  value?: any | null;
}

export interface ClassificationItem {
  /** Children classifications */
  children?: ClassificationItem[];

  /** Products count */
  hitCount: number;

  /** Configurator items hit count */
  configuratorHitCount?: number;

  /** Has children.
If true node has child nodes. When lower levels are not requested then Pis.Products.Models.Dto.Classifications.ClassificationItem.Children array will be empty and HasChildren will be true. */
  hasChildren: boolean;

  /** Classification images. */
  images?: Image[];

  /** CID of the classification */
  cid: string;

  /** Name of the classification */
  name: string;

  /** Node level */
  nodeLevel?: string;

  /** Node use */
  nodeUse?: string;

  /** Classification additional info.
List of Pis.Products.Models.Dto.Classifications.ClassificationAdditionalInfo */
  additionalInfo?: ClassificationAdditionalInfo[];
}

export interface ClassificationNode {
  /** CID of the classification */
  cid: string;

  /** Name of the classification */
  name: string;

  /** Node level */
  nodeLevel?: string;

  /** Node use */
  nodeUse?: string;

  /** Classification additional info.
List of Pis.Products.Models.Dto.Classifications.ClassificationAdditionalInfo */
  additionalInfo?: ClassificationAdditionalInfo[];
}

export interface ClassificationNodesQuery {
  /**  */
  search: ClassificationNodesSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface ClassificationNodesSearchResult {
  /** List of the Pis.Products.Models.Dto.Classifications.ClassificationNode items */
  items?: ClassificationNode[];
}

export interface ClassificationNodesSearchSettings {
  /** CID's of the tree nodes */
  cids: string[];
}

export interface ClassificationParentsQuery {
  /**  */
  search: ClassificationParentsSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface ClassificationParentsSearchSettings {
  /** CID of the tree node */
  cid: string;
}

export interface ClassificationPriceListNode {
  /** CID of the classification */
  cid: string;

  /** Parent cid of the classification */
  parentCid?: string;

  /** Name of the classification */
  name: string;
}

export interface ClassificationPriceListNodesSearchResult {
  /** List of the Pis.Products.Models.Dto.Classifications.ClassificationPriceListNode items */
  items?: ClassificationPriceListNode[];
}

export interface ClassificationQuery {
  /**  */
  search: ClassificationSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface ClassificationSearchSettings {
  /** CID of the tree node
If CID will be not provided ROOT tree will be returned */
  cid?: string;

  /** Tree depth to retrieve.
Default value: 0 - returns all child's of Cid node. */
  treeDepth: number;

  /** Returns configurator hit counts in tree */
  configurators?: boolean;
}

export interface ClassificationsBrowserAppSettings {
  /**  */
  client?: ClientInfo;

  /** Country code.
The Country Market Availability and Country Market Availability Type attribute values associated with a product
instance determine the countries where a product will be seen.
Filters the results to only the product instances available for the designated Country */
  countryCode?: string;

  /** Application code.
The Publishers and Publishers Type attribute values associated with a product instance determine the availability
in front end application(s).
Property required. */
  appCode: string;

  /** Language code.
Web labels, attribute names, and attribute values will be presented in the requested translated language (if the
translations exist).
Default value: "en" */
  langCode?: string;

  /**  */
  filters?: ClassificationsBrowserFilters;

  /** Product Line Period.
Allows the user to select the product line period of the Product Line Tree that will displayed in the Product Line Tree link. */
  productLinePeriod?: string;
}

export interface ClassificationsBrowserAutocompleteQuery {
  /**  */
  search: ClassificationsBrowserSearchSettings;

  /**  */
  appSettings: ClassificationsBrowserAppSettings;

  /**  */
  pager?: PagerSettings;
}

export interface ClassificationsBrowserAutocompleteResult {
  /** Total count of matches */
  totalCount: number;

  /** List of alphabetically sorted matches */
  matches?: string[];
}

export interface ClassificationsBrowserChildrenQuery {
  /**  */
  search: ClassificationsBrowserChildrenSearchSettings;

  /**  */
  appSettings: ClassificationsBrowserAppSettings;
}

export interface ClassificationsBrowserChildrenResult {
  /** List of the Pis.Products.Models.Dto.ClassificationsBrowser.ClassificationsBrowserNode items */
  items?: ClassificationsBrowserNode[];
}

export interface ClassificationsBrowserChildrenSearchSettings {
  /** CID of the tree node */
  cid: string;

  /** List of selected CID's */
  selectedCids?: string[];

  /**  */
  treeType?: ClassificationsBrowserTreeTypeEnum;
}

export interface ClassificationsBrowserFilters {
  /**  */
  applicationUse?: ApplicationUseSettings;

  /** List of Life Cycle Phase.
As a default, the Classifications Browser view shows only nodes with a Life Cycle Phase of Active and Restricted. To include nodes with additional Life Cycle Phases, the valid Life Cycle Phase codes should be entered here. */
  lifeCyclePhases?: string[];
}

export interface ClassificationsBrowserNode {
  /** CID of the classification */
  cid: string;

  /** Name of the classification */
  name: string;

  /** English name of the classification */
  enName?: string;

  /** List of children */
  children?: ClassificationsBrowserNode[];

  /** Exact Match is true when search text matches node */
  match: boolean;

  /** Indicates leaf node */
  leaf: boolean;

  /**  */
  tree: ClassificationsBrowserTreeTypeEnum;

  /** Indicates if node can be selected */
  selectable: boolean;

  /**  */
  productGroup: ProductGroup;
}

export interface ClassificationsBrowserPathNode {
  /** CID of the classification */
  cid: string;

  /**  */
  tree?: ClassificationsBrowserTreeTypeEnum;
}

export interface ClassificationsBrowserPathQuery {
  /**  */
  search: ClassificationsBrowserPathSearchSettings;

  /**  */
  appSettings: ClassificationsBrowserAppSettings;
}

export interface ClassificationsBrowserPathSearchSettings {
  /**  */
  node: ClassificationsBrowserPathNode;
}

export interface ClassificationsBrowserSearchQuery {
  /**  */
  search: ClassificationsBrowserSearchSettings;

  /**  */
  appSettings: ClassificationsBrowserAppSettings;
}

export interface ClassificationsBrowserSearchResult {
  /** List of the Pis.Products.Models.Dto.ClassificationsBrowser.ClassificationsBrowserNode items */
  items?: ClassificationsBrowserNode[];

  /** List of the Pis.Products.Models.Dto.ClassificationsBrowser.ClassificationsBrowserNode selected items */
  selectedItems?: ClassificationsBrowserNode[];

  /** Indicates if the limit was exceeded */
  limitExceeded?: boolean;

  /** Limit value */
  limit?: number;
}

export interface ClassificationsBrowserSearchSettings {
  /** Text to be search */
  searchText?: string;

  /** List of selected CID's */
  selectedCids?: string[];

  /** List of favorite CID's */
  favoriteCids?: string[];

  /**  */
  treeView?: ClassificationsBrowserTreeViewEnum;
}

export interface ClassificationsBrowserValidateNode {
  /** CID of the classification */
  cid: string;

  /**  */
  tree?: ClassificationsBrowserTreeTypeEnum;
}

export interface ClassificationsBrowserValidateQuery {
  /**  */
  search: ClassificationsBrowserValidateSearchSettings;

  /**  */
  appSettings: ClassificationsBrowserAppSettings;
}

export interface ClassificationsBrowserValidateResult {
  /** List of the valid nodes */
  validNodes?: ClassificationsBrowserNode[];

  /** List of the invalid nodes */
  invalidNodes?: ClassificationsBrowserValidateNode[];
}

export interface ClassificationsBrowserValidateSearchSettings {
  /** List of nodes */
  nodes: ClassificationsBrowserValidateNode[];
}

export interface ClassificationsSearchResult {
  /** Additional info */
  additionalInfo?: ClassificationAdditionalInfo[];

  /** List of the Pis.Products.Models.Dto.Classifications.ClassificationItem items */
  items?: ClassificationItem[];
}

export interface ClientInfo {
  /** End client IP address. */
  ipAddress?: string;

  /** End client user agent (browser) */
  userAgent?: string;
}

export interface CompareClassificationItem {
  /** Breadcrumb. */
  breadcrumb?: BreadcrumbItem[];

  /** Children classifications */
  children?: ClassificationItem[];

  /** Products count */
  hitCount: number;

  /** Configurator items hit count */
  configuratorHitCount?: number;

  /** Has children.
If true node has child nodes. When lower levels are not requested then Pis.Products.Models.Dto.Classifications.ClassificationItem.Children array will be empty and HasChildren will be true. */
  hasChildren: boolean;

  /** Classification images. */
  images?: Image[];

  /** CID of the classification */
  cid: string;

  /** Name of the classification */
  name: string;

  /** Node level */
  nodeLevel?: string;

  /** Node use */
  nodeUse?: string;

  /** Classification additional info.
List of Pis.Products.Models.Dto.Classifications.ClassificationAdditionalInfo */
  additionalInfo?: ClassificationAdditionalInfo[];
}

export interface CompareProductDetail {
  /**  */
  item?: ProductItem;

  /** Attribute groups */
  attributeGroups?: AttributeGroup[];
}

export interface CompareQuery {
  /**  */
  search: CompareSearchSettings;

  /** List of attribute codes for which retrieve values. Overrides default AGM (Attribute Group Management) list of
attributes.
Attribute code can use "*" pattern to match more than one attributes. For example: "LeadTime_*" */
  attributeCodes?: string[];

  /**  */
  appSettings: AppSettings;
}

export interface CompareResult {
  /** Classifications */
  classifications?: CompareClassificationItem[];

  /** Compare product detail items */
  items?: CompareProductDetail[];
}

export interface CompareSearchSettings {
  /** List of product ids */
  productIds: string[];

  /**  */
  mergeMethod: AttributeMergeMethodEnum;

  /** Differences only.
When true attribute rows with all values equal are removed. */
  differencesOnly?: boolean;

  /** List of CID's */
  cids?: string[];

  /** Strict mode.
When true only attributes used on DetailPage will be compared. */
  strictMode?: boolean;

  /** Special document handling.
When true: If there is a value available for any document attribute, convert the values to 'Y' in the compare
component. */
  specialDocumentHandling?: boolean;
}

export interface Configuration {
  /** Configuration product number */
  productNumber: string;

  /** List of Pis.Catalogs.Models.Dto.Products.ConfigurationGroup groups */
  groups?: ConfigurationGroup[];

  /** Configuration modified date */
  dateModified: Date;

  /** Configuration created date */
  dateCreated: Date;

  /** Configuration xml.
This XML does not contains entire xml; only ItemDetails node. */
  xml: string;

  /** Configuration unique identifier */
  id: string;

  /** Configuration product id */
  productId: string;
}

export interface ConfigurationActionResult {
  /** Configurations created \/ updated */
  configurations?: Configuration[];

  /** Action success */
  success: boolean;

  /** Action message */
  message?: string;

  /** Action errors */
  errors?: ActionInfo[];

  /** Action warnings */
  warnings?: ActionInfo[];
}

export interface ConfigurationAddAction {
  /** Quantity unique identifier */
  quantityId: string;

  /** Configuration xml */
  xml: string;

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface ConfigurationDeleteAction {
  /** Configuration id */
  id: string;

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface ConfigurationElement {
  /** Product */
  product: string;

  /** Description */
  description: string;

  /** Quantity */
  quantity: number;

  /** Unit of measure */
  unitOfMeasure: string;
}

export interface ConfigurationGetQuery {
  /** Configuration id */
  id: string;

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface ConfigurationGroup {
  /** Name */
  name: string;

  /** List of Pis.Catalogs.Models.Dto.Products.ConfigurationElement elements */
  elements: ConfigurationElement[];
}

export interface ConfigurationId {
  /** Configuration unique identifier */
  id: string;

  /** Configuration product id */
  productId: string;
}

export interface ConfigurationItem {
  /** Editable */
  editable: boolean;

  /** Configuration product number */
  productNumber: string;

  /** List of Pis.Catalogs.Models.Dto.Products.ConfigurationGroup groups */
  groups?: ConfigurationGroup[];

  /** Configuration modified date */
  dateModified: Date;

  /** Configuration created date */
  dateCreated: Date;

  /** Configuration xml.
This XML does not contains entire xml; only ItemDetails node. */
  xml: string;

  /** Configuration unique identifier */
  id: string;

  /** Configuration product id */
  productId: string;
}

export interface ConfigurationUpdateAction {
  /** Configuration id */
  id: string;

  /** Configuration xml */
  xml: string;

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface ConfiguratorItem {
  /** Product id */
  productId: string;

  /** Description */
  description: string;

  /** Code */
  code: string;
}

export interface ConfiguratorItemSearchResult {
  /** List of Pis.Products.Models.Dto.ConfiguratorItems.ConfiguratorItem items */
  items?: ConfiguratorItem[];
}

export interface CountQuery {
  /**  */
  appSettings: AppSettings;

  /**  */
  search: SearchSettings;
}

export interface CountSearchResult {
  /** Total count of matches */
  totalCount: number;
}

export interface Criteria {
  /** Attribute code or "CID" */
  code: string;

  /** Criteria values. For attribute code attribute value is expected. For "CID" node identifier is expected.
Wildcard supported for attribute values only:
- starts with: Value*
- ends with: *Value
- contains: *Value* */
  values: string[];
}

export interface CustomFieldItem {
  /** Custom fields key */
  key: string;

  /** Custom field value */
  value: string;
}

export interface Customer {
  /** Customer system id */
  systemId: string;

  /** Customer sales unit */
  salesUnit: string;

  /** Customer customer id */
  customerId: string;
}

export interface DetailQuery {
  /**  */
  search: ProductDetailSettings;

  /** Detail data types to retrieve */
  dataTypes: DetailDataTypeEnum[];

  /** List of attribute codes for which retrieve values. Overrides default AGM (Attribute Group Management) list of
attributes.
Attribute code can use "*" pattern to match more than one attributes. For example: "LeadTime_*" */
  attributeCodes?: string[];

  /**  */
  appSettings: AppSettings;
}

export interface ErrorResult {
  /** Error message */
  message?: string;

  /** Error details */
  errorDetails?: string;

  /** Error trace id */
  traceId?: string;

  /**  */
  type?: ErrorResultType;
}

export interface ExportActionResult {
  /** Download guid */
  guid?: string;

  /** Action success */
  success: boolean;

  /** Action message */
  message?: string;

  /** Action errors */
  errors?: ActionInfo[];

  /** Action warnings */
  warnings?: ActionInfo[];
}

export interface FavoriteView {
  /** Favorite view id */
  id?: string;

  /** Favorite view code */
  code?: string;

  /**  */
  data?: FavoriteViewData;
}

export interface FavoriteViewData {
  /**  */
  treeType: TreeTypeEnum;

  /**  */
  pager: PagerSettings;

  /**  */
  search: SearchSettings;

  /**  */
  sort: SortSettings;
}

export interface FavoriteViewDeleteQuery {
  /**  */
  favoriteView: FavoriteViewDeleteSettings;

  /**  */
  appSettings: AppSettings;
}

export interface FavoriteViewDeleteSettings {
  /** Favorite view identifier */
  id: string;
}

export interface FavoriteViewSaveQuery {
  /**  */
  favoriteView: FavoriteViewSaveSettings;

  /**  */
  appSettings: AppSettings;
}

export interface FavoriteViewSaveSettings {
  /** Favorite view id */
  id?: string;

  /** Favorite view code */
  code: string;

  /**  */
  data: FavoriteViewData;
}

export interface FavoriteViewsQuery {
  /**  */
  search: FavoriteViewsSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface FavoriteViewsSearchResult {
  /** List of Pis.Products.Models.Dto.FavoriteViews.FavoriteView items */
  items?: FavoriteView[];
}

export interface FavoriteViewsSearchSettings {
  /** Favorite views identifiers */
  ids: string[];
}

export interface FeedbackQuery {
  /**  */
  feedback: FeedbackSettings;

  /**  */
  appSettings: AppSettings;
}

export interface FeedbackSettings {
  /** Feedback product id */
  productId: string;

  /** Feedback user name */
  userName?: string;

  /** Feedback user email */
  userEmail?: string;

  /** Feedback text */
  text: string;

  /**  */
  rating: FeedbackRatingEnum;
}

export interface FilterAttributeValue {
  /** Value code */
  code?: string;

  /** Value name */
  name: string;
}

export interface FilterItem {
  /** Attribute code */
  attributeCode: string;

  /** Attribute name */
  attributeName: string;

  /** Products hit count */
  hitCount: number;

  /** Determines if FilterItem should be promoted
In case of true, those filters should be displayed and rest should ba collapsed */
  isPromoted: boolean;
}

export interface FilterSearchResult {
  /** List of Pis.Products.Models.Dto.Filters.FilterItem items */
  items?: FilterItem[];
}

export interface FilterSearchSettings {
  /** List of filter attribute codes */
  attributeCodes: string[];

  /** CID of the tree node */
  cid?: string;

  /**  */
  partType: PartTypeEnum;

  /** List of Pis.Products.Models.Actions.IFilterCommonSearchSettings.FilterSettings for which data will be filtered */
  filterSettings?: FilterSettings[];

  /** Text to be search */
  searchText?: string;

  /**  */
  searchType: SearchTypeEnum;
}

export interface FilterSettings {
  /** Filter attribute code */
  attributeCode?: string;

  /** Filter attribute values.
Obsolete: To be removed, use Values instead */
  attributeValues?: string[];

  /** Filter attribute values */
  values?: FilterAttributeValue[];
}

export interface FilterValue {
  /** Products hit count */
  hitCount: number;

  /** Value code */
  code?: string;

  /** Value name */
  name: string;
}

export interface FilterValueItem {
  /** List of Pis.Products.Models.Dto.Filters.FilterValue values */
  values?: FilterValue[];

  /** Attribute code */
  attributeCode: string;

  /** Attribute name */
  attributeName: string;

  /** Products hit count */
  hitCount: number;

  /** Determines if FilterItem should be promoted
In case of true, those filters should be displayed and rest should ba collapsed */
  isPromoted: boolean;
}

export interface FilterValueSearchResult {
  /** List of Pis.Products.Models.Dto.Filters.FilterValueItem items */
  items?: FilterValueItem[];
}

export interface FiltersQuery {
  /**  */
  search: FilterSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface Image {
  /** Url of image */
  url: string;

  /** Thumbnail url of image */
  thumbnailUrl: string;

  /** Master url of image */
  masterUrl: string;
}

export interface ImageByClassificationCidsQuery {
  /**  */
  search: ImageClassificationSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface ImageByImageIdsQuery {
  /**  */
  search: ImageSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface ImageByProductIdsQuery {
  /**  */
  search: ImageProductSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface ImageClassificationSearchSettings {
  /** List of classification CID's */
  cids: string[];
}

export interface ImageItem {
  /** Image id */
  imageId?: string;

  /** Product ID */
  productId?: string;

  /** Product type */
  productType?: string;

  /** Product class */
  productClass?: string;

  /** Global ID */
  globalId?: string;

  /** CID */
  cid?: string;

  /** Url of image */
  url: string;

  /** Thumbnail url of image */
  thumbnailUrl: string;

  /** Master url of image */
  masterUrl: string;
}

export interface ImageProductSearchSettings {
  /** List of product id or global id */
  products?: string[];

  /** List of product ids */
  productIds?: string[];
}

export interface ImageSearchResult {
  /** List of Pis.Products.Models.Dto.Images.ImageItem items */
  items?: ImageItem[];
}

export interface ImageSearchSettings {
  /** List of image ids */
  imageIds: string[];
}

export interface InteractiveGuide {
  /** Description */
  description: string;

  /** Document number */
  documentNumber?: string;

  /** File type (extension) */
  fileType?: string;

  /**  */
  securityLevel: SecurityLevelEnum;

  /** Url to file */
  url?: string;

  /**  */
  type?: InteractiveGuideTypeEnum;

  /** Type description */
  typeDescription?: string;
}

export interface InteractiveGuidesSearchResult {
  /** List of Pis.Products.Models.Dto.InteractiveGuides.InteractiveGuide items */
  items?: InteractiveGuide[];
}

export interface JobAction {
  /** Job unique identifier */
  jobId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface JobItem {
  /** Job unique identifier */
  id: string;

  /**  */
  owner: Owner;

  /**  */
  catalog?: Catalog;

  /**  */
  type: JobTypeEnum;

  /**  */
  status: JobStatusEnum;

  /** Catalog modified date */
  dateModified: Date;

  /** Catalog created date */
  dateCreated: Date;
}

export interface JobSearchQuery {
  /**  */
  sort?: JobSortSettings;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface JobSearchResult {
  /** List of job items */
  items?: JobItem[];
}

export interface JobSortSettings {
  /**  */
  field: JobSortFieldEnum;

  /**  */
  direction: SortDirectionEnum;
}

export interface Link {
  /** Text of link */
  text: string;

  /** Product id
This value exists only for the Link equals Detail */
  productId?: string;

  /** Search text
This value exists only for the Link equals Search */
  searchText?: string;

  /** Cid
This value exists only for the Link equals Classification */
  cid?: string;

  /** Document id
This value exists only for the Link equals Document */
  documentId?: string;

  /**  */
  type: LinkTypeEnum;
}

export interface ListQuery {
  /**  */
  search: ListSearchSettings;

  /** List of attribute codes for which retrieve values (this list replaces default attributes).
Attribute code can use "*" pattern to match more than one attributes. For example: "LeadTime_*" */
  attributeCodes?: string[];

  /**  */
  appSettings: AppSettings;
}

export interface ListSearchSettings {
  /** List of product ids */
  productIds: string[];
}

export interface LoginQuery {
  /** Client id. */
  clientId: string;

  /** Client secret. */
  clientSecret: string;
}

export interface LoginResult {
  /** Access token */
  accessToken?: string;
}

export interface MasterProduct {
  /** Product id */
  productId: string;

  /** Number of spare parts */
  hitCount: number;

  /** Description of master product */
  description: string;
}

export interface MasterProductSearchResult {
  /** List of Pis.Products.Models.Dto.MasterProducts.MasterProduct items */
  items?: MasterProduct[];

  /** Master products total count
In case that property is greater than 0 and Items is null that means to many results was found. */
  totalCount: number;
}

export interface NotFoundResult {
  /**  */
  statusCode?: number;
}

export interface Owner {
  /** Owner unique identifier */
  id?: string;

  /** User id */
  userId?: string;

  /** System id */
  systemId?: string;

  /** Customer id */
  customerId?: string;

  /** Sales unit */
  salesUnit?: string;

  /**  */
  type?: OwnerTypeEnum;
}

export interface PagerSettings {
  /** Page number
Starts from 1 */
  page: number;

  /** Page size */
  pageSize: number;
}

export interface PartType {
  /**  */
  type?: PartTypeEnum;

  /** Part type name */
  name?: string;
}

export interface PartTypesSearchResult {
  /** List of Pis.Products.Models.Dto.PartTypes.PartType items */
  items?: PartType[];
}

export interface PdfQuery {
  /**  */
  search: ProductSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface PreviewQuery {
  /**  */
  search: ProductPreviewSettings;

  /** List of attribute codes for which retrieve values. Overrides default AGM (Attribute Group Management) list
attributes.
Attribute code can use "*" pattern to match more than one attributes. For example: "LeadTime_*" */
  attributeCodes?: string[];

  /**  */
  appSettings: AppSettings;
}

export interface PriceInquiryQuery {
  /**  */
  priceInquiry: PriceInquirySettings;

  /**  */
  appSettings: AppSettings;
}

export interface PriceInquirySettings {
  /** Price inquiry product id */
  productId?: string;

  /** Price inquiry user name */
  userName?: string;

  /** Price inquiry customer code */
  customerCode?: string;

  /** Price inquiry spare part supplier code */
  sparePartSupplierCode?: string;

  /** Price inquiry sales price available */
  salesPriceAvailable?: boolean;

  /** Price inquiry transfer price available */
  transferPriceAvailable?: boolean;

  /** Price inquiry sales price error code */
  salesPriceErrorCode?: string;

  /** Price inquiry transfer price error code */
  transferPriceErrorCode?: string;
}

export interface PriceListQuery {
  /** List of attribute codes for which retrieve values
Attribute code can use "*" pattern to match more than one attributes. For example: "LeadTime_*" */
  attributeCodes?: string[];

  /**  */
  appSettings: AppSettings;

  /**  */
  pager?: PagerSettings;

  /**  */
  search?: SearchSettings;

  /**  */
  sort?: SortSettings;
}

export interface PriceListSearchResult {
  /**  */
  classifications?: ClassificationPriceListNodesSearchResult;

  /**  */
  products?: ProductPriceListSearchResult;
}

export interface PrintResult {
  /** Download guid */
  guid?: string;
}

export interface PrmEntitiesQuery {
  /**  */
  search: PrmEntitiesSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface PrmEntitiesSearchResult {
  /** List of Pis.Products.Models.Dto.Prm.ProductPrmRelationshipEntities items */
  items?: ProductPrmRelationshipEntities[];
}

export interface PrmEntitiesSearchSettings {
  /** Product Ids
If Product Ids will be provided, suppliers property will be ignored */
  productIds?: string[];

  /** Suppliers
If Product Ids will be provided, Suppliers property will be ignored */
  suppliers?: string[];

  /** Filter result by prm list code */
  prmListCodes?: string[];

  /** Filter result by prm type code */
  prmTypeCodes?: string[];
}

export interface PrmFilterSettings {
  /** CID of the tree node */
  cid?: string;

  /** Prm relationship code */
  relationshipCode?: string;

  /**  */
  relationshipType: PrmRelationshipTypeEnum;

  /** Search relationship products by available properties */
  searchText?: string;

  /** List of Attribute codes for which retrieve values */
  attributeCodes: string[];

  /** List of Pis.Products.Models.Actions.PrmFilterSettings.FilterSettings for which data will be filtered */
  filterSettings?: FilterSettings[];

  /** Product id */
  productId: string;
}

export interface PrmFiltersQuery {
  /**  */
  search: PrmFilterSettings;

  /**  */
  appSettings: AppSettings;
}

export interface PrmProductInfoEntity {
  /** Ghost product cids */
  ghostProductCids?: string[];

  /** Global id */
  globalId?: string;

  /** Id */
  id?: string;

  /**  */
  idType?: PrmIdTypeEnum;

  /** Order */
  order?: number;

  /** Sp roll down */
  spRollDown?: boolean;

  /** User field values */
  userFieldValues?: PrmUserFieldValueEntity[];
}

export interface PrmQuery {
  /**  */
  pager?: PagerSettings;

  /**  */
  search: PrmSearchSettings;

  /**  */
  appSettings: AppSettings;
}

export interface PrmRelationship {
  /** PRM relationship code */
  code: string;

  /** PRM relationship description */
  description: string;

  /**  */
  type: PrmRelationshipTypeEnum;

  /** PRM images */
  images?: string[];

  /**  */
  table?: Table;

  /** List of Pis.Products.Models.Dto.Prm.UserField user fields */
  userFields?: UserField[];

  /** List of filters */
  filters?: FilterValueItem[];
}

export interface PrmRelationshipEntity {
  /** PRM relationship code */
  code: string;

  /** PRM relationship description */
  description: string;

  /** PRM relationship org type */
  orgType?: string;

  /** PRM relationship org code */
  orgCode?: string;

  /** PRM images */
  images?: string[];

  /** PRM relationship type code */
  typeCode?: string;

  /** PRM relationship type description */
  typeDescription?: string;

  /** Master products */
  masterProducts?: PrmProductInfoEntity[];

  /** Related products */
  relatedProducts?: PrmProductInfoEntity[];

  /** Relationship user field values */
  relationshipUserFieldValues?: PrmUserFieldValueEntity[];

  /** Type user field values */
  typeUserFieldValues?: PrmUserFieldValueEntity[];
}

export interface PrmSearchResult {
  /** List of Pis.Products.Models.Dto.Prm.PrmRelationship items */
  items?: PrmRelationship[];
}

export interface PrmSearchSettings {
  /** CID of the tree node */
  cid?: string;

  /** Filter relationship products by available properties.
Obsolete: To be removed, use SearchText instead. */
  filter?: string;

  /** Prm relationship code */
  relationshipCode?: string;

  /**  */
  relationshipType: PrmRelationshipTypeEnum;

  /** Search relationship products by available properties */
  searchText?: string;

  /** List of Pis.Products.Models.Actions.PrmSearchSettings.FilterSettings for which data will be filtered */
  filterSettings?: FilterSettings[];

  /** Product id */
  productId: string;
}

export interface PrmUserFieldValueEntity {
  /** Code */
  code?: string;

  /** Description */
  description?: string;

  /** Order */
  order?: number;

  /** Values */
  values?: string[];
}

export interface ProductAdditionalInfo {
  /**  */
  type?: ProductAdditionalInfoTypeEnum;

  /** Text */
  text?: string;
}

export interface ProductAttribute {
  /**  */
  type: AttributeTypeEnum;

  /** Attribute code */
  attributeCode: string;

  /** Attribute translated name */
  attributeName?: string;

  /** Attribute values
Only for Pis.Products.Models.Dto.Products.AttributeTypeEnum.TextPis.Products.Models.Dto.Products.AttributeTypeEnum.Enum type */
  values?: AttributeValue[];

  /**  */
  table?: Table;

  /** Is that internal attribute */
  isInternal: boolean;

  /** Tool tip */
  tooltip?: string;

  /** Highlight
Indicates if the Product Attribute should be highlighted. */
  highlight: boolean;
}

export interface ProductClassificationsSearchResult {
  /** Dictionary of product breadcrumbs
Key is Pis.Products.Models.Dto.TreeTypeEnum */
  items?: object;
}

export interface ProductDetail {
  /**  */
  productDetails?: ProductDetailSearchResult;

  /**  */
  attributeGroups?: AttributeGroupSearchResult;

  /**  */
  productClassifications?: ProductClassificationsSearchResult;

  /**  */
  productRelationships?: PrmSearchResult;

  /**  */
  interactiveGuides?: InteractiveGuidesSearchResult;

  /**  */
  relatedLinks?: RelatedLinksSearchResult;

  /**  */
  relatedProducts?: RelatedProductsSearchResult;
}

export interface ProductDetailSearchResult {
  /**  */
  item?: ProductItem;
}

export interface ProductDetailSettings {
  /** CID of the tree node */
  cid?: string;

  /** Product id */
  productId: string;
}

export interface ProductGroup {
  /**  */
  status: ProductGroupValidationStatusEnum;

  /** Product group code */
  code?: string;

  /** Product group name */
  name?: string;
}

export interface ProductItem {
  /** List of aliases */
  aliases?: string[];

  /** Product Replacements information.
List of Pis.Products.Models.Dto.Products.ProductReplacement */
  replacements?: ProductReplacement[];

  /** Product additional info
List of Pis.Products.Models.Dto.Products.ProductAdditionalInfo */
  additionalInfo?: ProductAdditionalInfo[];

  /** Specifies whenever product is inactive and navigation for details and preview should be disabled. */
  inactive?: boolean;

  /** Hides detail images
Property is calculated based on Pis.Products.Models.AppSettings.AnonymousUser (maintained in PIS Dashboard) and
AnonymousUserHideImage of item */
  hideImages?: boolean;

  /** Hides detail "Where Used" information for Kit components
Property is calculated based on Pis.Products.Models.AppSettings.AnonymousUser (maintained in PIS Dashboard) and
AnonymousUserHideWhereUsed of item */
  hideWhereUsed?: boolean;

  /** Product images. */
  images?: Image[];

  /** Product id */
  productId: string;

  /** Product domain */
  productIdDomain?: string;

  /** Dictionary of product attributes
Key is attribute code */
  attributes: {[key: string]: ProductAttribute};

  /** Exact Match is true when search text matches any of product identifiers. */
  exactMatch: boolean;

  /** Identifies whether product is spare part. */
  sparePart: boolean;

  /**  */
  classifications?: ProductItemClassifications;
}

export interface ProductItemClassifications {
  /** Product Classifications (PC) */
  classCids?: string[];

  /** Product Type Cids (T) */
  typeCids?: string[];

  /** Product leaf Cids */
  leafCids?: string[];

  /** Product Level 0 (ROOT) Cids */
  level0Cids?: string[];

  /** Breadcrumbs.
All item classifications with full path. */
  breadcrumbs?: object;
}

export interface ProductListItem {
  /** Product id */
  productId: string;

  /** Product domain */
  productIdDomain?: string;

  /** Dictionary of product attributes
Key is attribute code */
  attributes: {[key: string]: ProductAttribute};

  /** Exact Match is true when search text matches any of product identifiers. */
  exactMatch: boolean;

  /** Identifies whether product is spare part. */
  sparePart: boolean;

  /**  */
  classifications?: ProductItemClassifications;
}

export interface ProductListSearchResult {
  /** List of product item */
  items?: ProductListItem[];

  /** Total items count */
  totalCount: number;
}

export interface ProductPreview {
  /**  */
  item?: ProductItem;
}

export interface ProductPreviewSettings {
  /** CID of the tree node */
  cid?: string;

  /** List of Pis.Products.Models.Actions.IFilterCommonSearchSettings.FilterSettings for which data will be filtered */
  filterSettings?: FilterSettings[];

  /** Product id */
  productId: string;
}

export interface ProductPriceList {
  /** Global id */
  globalId?: string;

  /** Product id */
  productId?: string;

  /** List of Pis.Products.Models.Dto.PriceLists.ProductPriceListAttribute items */
  attributes?: ProductPriceListAttribute[];
}

export interface ProductPriceListAttribute {
  /** Attribute Code */
  attributeCode: string;

  /** Attribute values */
  values?: string[];
}

export interface ProductPriceListSearchResult {
  /** List of Pis.Products.Models.Dto.PriceLists.ProductPriceList items */
  items?: ProductPriceList[];

  /** Total products count */
  totalCount: number;
}

export interface ProductPrmRelationshipEntities {
  /** Product id */
  productId?: string;

  /** Supplier */
  supplier?: string;

  /** List of Pis.Products.Models.Dto.Prm.PrmRelationshipEntity relationships */
  relationships?: PrmRelationshipEntity[];
}

export interface ProductReplacement {
  /** Label */
  label: string;

  /**  */
  link?: Link;
}

export interface ProductSearchResult {
  /** List of product item */
  items?: ProductItem[];

  /** Total items count */
  totalCount: number;
}

export interface ProductSearchSettings {
  /** Product id */
  productId: string;
}

export interface Quantity {
  /** Quantity id */
  id: string;

  /** Quantity value */
  value: number;

  /** Quantity unit of measure */
  unitOfMeasure: string;

  /** Store */
  store?: string;

  /** Configuration ids */
  configurationIds?: ConfigurationId[];

  /** Quantity modified date */
  dateModified: Date;

  /** Quantity created date */
  dateCreated: Date;
}

export interface QuantityActionResult {
  /** Quantities created \/ updated */
  quantities?: Quantity[];

  /** Action success */
  success: boolean;

  /** Action message */
  message?: string;

  /** Action errors */
  errors?: ActionInfo[];

  /** Action warnings */
  warnings?: ActionInfo[];
}

export interface QuantityAddAction {
  /** List of quantities */
  quantities: QuantityAddItem[];

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface QuantityAddItem {
  /** Product id */
  productId: string;

  /** Unit of measure */
  unitOfMeasure: string;

  /** Quantity value */
  value: number;

  /** Store */
  store?: string;
}

export interface QuantityCopyAction {
  /** Source catalog unique identifier */
  sourceCatalogId: string;

  /** Target catalog unique identifier */
  targetCatalogId: string;

  /** List of source quantity unique identifiers */
  sourceQuantityIds: string[];

  /**  */
  appSettings: CatalogAppSettings;
}

export interface QuantityCopyToNewAction {
  /** Source catalog unique identifier */
  sourceCatalogId: string;

  /** New catalog code */
  code: string;

  /**  */
  type: CatalogTypeEnum;

  /** New catalog description */
  description: string;

  /** List of source quantity unique identifiers */
  sourceQuantityIds: string[];

  /**  */
  appSettings: CatalogAppSettings;
}

export interface QuantityDeleteAction {
  /** List of quantities */
  quantities: QuantityDeleteItem[];

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface QuantityDeleteItem {
  /** Quantity unique identifier */
  id: string;
}

export interface QuantityItem {
  /** Editable */
  editable: boolean;

  /** Quantity id */
  id: string;

  /** Quantity value */
  value: number;

  /** Quantity unit of measure */
  unitOfMeasure: string;

  /** Store */
  store?: string;

  /** Configuration ids */
  configurationIds?: ConfigurationId[];

  /** Quantity modified date */
  dateModified: Date;

  /** Quantity created date */
  dateCreated: Date;
}

export interface QuantityUpdateAction {
  /** List of quantities */
  quantities: QuantityUpdateItem[];

  /** Catalog unique identifier */
  catalogId: string;

  /**  */
  appSettings: CatalogAppSettings;
}

export interface QuantityUpdateItem {
  /** Quantity unique identifier */
  id: string;

  /** Quantity value */
  value: number;

  /** Store */
  store?: string;
}

export interface RelatedLink {
  /** Related link text */
  text?: string;

  /** Related link url */
  url?: string;

  /** Related link image */
  image?: string;

  /** Is related link internal */
  internal?: boolean;
}

export interface RelatedLinksSearchResult {
  /** List of Pis.Products.Models.Dto.RelatedLinks.RelatedLink items */
  items?: RelatedLink[];
}

export interface RelatedProduct {
  /** Related product condition */
  condition?: string;

  /** Related product id */
  productId?: string;

  /** Related product global commercial alias */
  gloComAli?: string;
}

export interface RelatedProductsSearchResult {
  /** Current product condition. */
  condition?: string;

  /** List of Pis.Products.Models.Dto.RelatedProducts.RelatedProduct items */
  items?: RelatedProduct[];
}

export interface Route {
  /**  */
  systemType: SystemTypeEnum;

  /** Route system id */
  systemId: string;

  /** Route ois */
  ois: string;
}

export interface RoutingTableActionResult {
  /** Routes created \/ updated */
  routes?: Route[];

  /** Action success */
  success: boolean;

  /** Action message */
  message?: string;

  /** Action errors */
  errors?: ActionInfo[];

  /** Action warnings */
  warnings?: ActionInfo[];
}

export interface RoutingTableGetQuery {
  /**  */
  appSettings: CatalogAppSettings;
}

export interface RoutingTablePostAction {
  /** Routes to post */
  routes: Route[];

  /**  */
  appSettings: CatalogAppSettings;
}

export interface SearchQuery {
  /** Search data types to retrieve */
  dataTypes: SearchDataTypeEnum[];

  /** List of attribute codes for which retrieve values (this list replaces default attributes).
Attribute code can use "*" pattern to match more than one attributes. For example: "LeadTime_*" */
  attributeCodes?: string[];

  /**  */
  appSettings: AppSettings;

  /**  */
  pager?: PagerSettings;

  /**  */
  search?: SearchSettings;

  /**  */
  sort?: SortSettings;

  /** Include product classifications (DataTypes must include Products) */
  includeProductClassifications?: boolean;
}

export interface SearchResult {
  /**  */
  classifications?: ClassificationsSearchResult;

  /**  */
  products?: ProductSearchResult;

  /**  */
  filters?: FilterSearchResult;

  /**  */
  breadcrumbs?: BreadcrumbsSearchResult;

  /**  */
  configurators?: ConfiguratorItemSearchResult;

  /**  */
  masterProducts?: MasterProductSearchResult;

  /**  */
  interactiveGuides?: InteractiveGuidesSearchResult;

  /**  */
  partTypes?: PartTypesSearchResult;
}

export interface SearchSettings {
  /** CID of the tree node */
  cid?: string;

  /**  */
  partType: PartTypeEnum;

  /** Master product id */
  masterProductId?: string;

  /** List of Pis.Products.Models.Actions.IFilterCommonSearchSettings.FilterSettings for which data will be filtered */
  filterSettings?: FilterSettings[];

  /** Text to be search */
  searchText?: string;

  /**  */
  searchType: SearchTypeEnum;
}

export interface SortSettings {
  /**  */
  field: SortFieldEnum;

  /**  */
  direction: SortDirectionEnum;
}

export interface State {
  /**  */
  accessToken: string;

  /**  */
  json?: any | null;
}

export interface StateAddQuery {
  /** State JSON */
  json: any | null;
}

export interface StockInquiryQuery {
  /**  */
  stockInquiry: StockInquirySettings;

  /**  */
  appSettings: AppSettings;
}

export interface StockInquirySettings {
  /** Stock inquiry product id */
  productId?: string;

  /** Stock inquiry user name */
  userName?: string;

  /** Stock inquiry customer code */
  customerCode?: string;

  /** Stock inquiry spare part supplier code */
  sparePartSupplierCode?: string;

  /** Stock inquiry available */
  stockInquiryAvailable?: boolean;

  /** Stock inquiry error code */
  stockInquiryErrorCode?: string;
}

export interface Table {
  /** List of Pis.Products.Models.Dto.Table.TableColumn columns */
  columns: TableColumn[];

  /** List of Pis.Products.Models.Dto.Table.TableRow rows */
  rows: TableRow[];

  /** Rows total count */
  rowsTotalCount: number;
}

export interface TableColumn {
  /** Text */
  text: string;

  /** Data field */
  dataField: string;
}

export interface TableRow {
  /** Dictionary of Pis.Products.Models.Dto.Table.TableColumn.DataField and Pis.Products.Models.Dto.Table.TableValue */
  values: {[key:string]:TableValue[]}[];
}

export interface TableValue {
  /** Text */
  text?: string;

  /** Tool tip */
  tooltip?: string;

  /**  */
  link?: Link;
}

export interface TranslationItem {
  /** Component name. */
  component: string;

  /** Resource name. */
  key: string;

  /** Translation value. */
  value: string;
}

export interface TranslationQuery {
  /** List of the component names
If property will be null or empty all components will be returned */
  components?: string[];

  /**  */
  appSettings: AppSettings;
}

export interface UserField {
  /** Name of the user field */
  name?: string;

  /** Values of the user field */
  values?: string[];
}

export enum AliasesEnum {
  'All' = 'All',
  'Blank' = 'Blank',
  'Populated' = 'Populated'
}

export enum AttributeMergeMethodEnum {
  'Merge' = 'Merge',
  'FirstFound' = 'FirstFound',
  'Flat' = 'Flat'
}

export enum AttributeTypeEnum {
  'Text' = 'Text',
  'Enum' = 'Enum',
  'Table' = 'Table',
  'NotApplicable' = 'NotApplicable',
  'Populated' = 'Populated'
}

export enum CatalogExportResultTypeEnum {
  'Download' = 'Download',
  'Job' = 'Job'
}

export enum CatalogProductSearchDataTypeEnum {
  'ProductClassifications' = 'ProductClassifications',
  'PartClassifications' = 'PartClassifications',
  'Products' = 'Products',
  'Filters' = 'Filters',
  'Breadcrumbs' = 'Breadcrumbs'
}

export enum CatalogProductSearchTypeEnum {
  'WithAllWords' = 'WithAllWords',
  'WithAtLeastOneWord' = 'WithAtLeastOneWord',
  'ExactPhrase' = 'ExactPhrase'
}

export enum CatalogProductSortFieldEnum {
  'LastImport' = 'LastImport',
  'Product' = 'Product',
  'Alias' = 'Alias',
  'LastModified' = 'LastModified',
  'Hierarchical' = 'Hierarchical'
}

export enum CatalogProductTypeEnum {
  'Listed' = 'Listed',
  'Unlisted' = 'Unlisted',
  'Configured' = 'Configured'
}

export enum CatalogSearchTypeEnum {
  'CatalogCodeAndDescription' = 'CatalogCodeAndDescription',
  'CreatedBy' = 'CreatedBy',
  'CatalogCode' = 'CatalogCode'
}

export enum CatalogSortFieldEnum {
  'LastModified' = 'LastModified',
  'Code' = 'Code',
  'Description' = 'Description'
}

export enum CatalogTypeEnum {
  'Company' = 'Company',
  'Personal' = 'Personal'
}

export enum ClassificationAdditionalInfoTypeEnum {
  'AllowPriceList' = 'AllowPriceList',
  'AllowPriceListTotalCount' = 'AllowPriceListTotalCount',
  'ParentNodeCid' = 'ParentNodeCid',
  'ParentNodeName' = 'ParentNodeName',
  'ProductGroup' = 'ProductGroup'
}

export enum ClassificationsBrowserTreeTypeEnum {
  'Offering' = 'Offering',
  'ProductLine' = 'ProductLine'
}

export enum ClassificationsBrowserTreeViewEnum {
  'Offering' = 'Offering',
  'ProductLine' = 'ProductLine',
  'Favorites' = 'Favorites'
}

export enum DetailDataTypeEnum {
  'ProductDetails' = 'ProductDetails',
  'ProductClassifications' = 'ProductClassifications',
  'AttributeGroups' = 'AttributeGroups',
  'ProductRelationships' = 'ProductRelationships',
  'InteractiveGuides' = 'InteractiveGuides',
  'RelatedLinks' = 'RelatedLinks'
}

export enum ErrorResultType {
  'BadRequest' = 'BadRequest',
  'NotAuthorized' = 'NotAuthorized',
  'ServerError' = 'ServerError'
}

export enum FeedbackRatingEnum {
  'Poor' = 'Poor',
  'Good' = 'Good',
  'Excellent' = 'Excellent'
}

export enum InteractiveGuideTypeEnum {
  'Product' = 'Product',
  'Classification' = 'Classification'
}

export enum JobSortFieldEnum {
  'Catalog' = 'Catalog',
  'JobType' = 'JobType',
  'JobStatus' = 'JobStatus',
  'LastModified' = 'LastModified'
}

export enum JobStatusEnum {
  'InQueue' = 'InQueue',
  'Running' = 'Running',
  'Success' = 'Success',
  'Error' = 'Error',
  'Cancelled' = 'Cancelled',
  'Cancelling' = 'Cancelling'
}

export enum JobTypeEnum {
  'ExportCatalogWithPrices' = 'ExportCatalogWithPrices'
}

export enum LinkTypeEnum {
  'None' = 'None',
  'Detail' = 'Detail',
  'Search' = 'Search',
  'Classification' = 'Classification',
  'Document' = 'Document',
  'TechnicalInformation' = 'TechnicalInformation'
}

export enum OwnerTypeEnum {
  'Company' = 'Company',
  'Personal' = 'Personal'
}

export enum PartTypeEnum {
  'All' = 'All',
  'Standard' = 'Standard',
  'Exchange' = 'Exchange',
  'Repair' = 'Repair',
  'NewAddition' = 'NewAddition',
  'PreventativeMaintenanceKit' = 'PreventativeMaintenanceKit'
}

export enum PrmIdTypeEnum {
  'GlobalId' = 'GlobalId',
  'ProductId' = 'ProductId',
  'Ean' = 'Ean',
  'Text' = 'Text',
  'NodeCid' = 'NodeCid'
}

export enum PrmRelationshipTypeEnum {
  'All' = 'All',
  'Accessories' = 'Accessories',
  'AlternativeSell' = 'AlternativeSell',
  'ConsistsOf' = 'ConsistsOf',
  'CrossSell' = 'CrossSell',
  'DeviceTypeCode' = 'DeviceTypeCode',
  'DeviceTypeList' = 'DeviceTypeList',
  'KitContents' = 'KitContents',
  'SoftwareList' = 'SoftwareList',
  'UpSell' = 'UpSell',
  'SparePartSelectionList' = 'SparePartSelectionList',
  'SparePartUsedOnList' = 'SparePartUsedOnList',
  'SuperCodeBoM' = 'SuperCodeBoM',
  'WhereUsed' = 'WhereUsed',
  'EnhancedSparePartFilters' = 'EnhancedSparePartFilters'
}

export enum ProductAdditionalInfoTypeEnum {
  'CustomerSpecificProduct' = 'CustomerSpecificProduct',
  'MarketLimitedProduct' = 'MarketLimitedProduct',
  'CustomerSpecificProductAndMarketLimitedProduct' = 'CustomerSpecificProductAndMarketLimitedProduct'
}

export enum ProductGroupValidationStatusEnum {
  'NonApplicable' = 'NonApplicable',
  'Valid' = 'Valid',
  'Invalid' = 'Invalid'
}

export enum SearchDataTypeEnum {
  'Classifications' = 'Classifications',
  'Products' = 'Products',
  'Filters' = 'Filters',
  'Breadcrumbs' = 'Breadcrumbs',
  'Configurators' = 'Configurators',
  'MasterProducts' = 'MasterProducts',
  'InteractiveGuides' = 'InteractiveGuides',
  'PartTypes' = 'PartTypes'
}

export enum SearchTypeEnum {
  'WithAllWords' = 'WithAllWords',
  'WithAtLeastOneWord' = 'WithAtLeastOneWord',
  'ExactPhrase' = 'ExactPhrase',
  'SparePartMasterIdentifier' = 'SparePartMasterIdentifier',
  'Hierarchical' = 'Hierarchical'
}

export enum SecurityLevelEnum {
  'Internal' = 'Internal',
  'External' = 'External'
}

export enum SortDirectionEnum {
  'Ascending' = 'Ascending',
  'Descending' = 'Descending'
}

export enum SortFieldEnum {
  'Product' = 'Product',
  'Relevance' = 'Relevance'
}

export enum SystemTypeEnum {
  'Oms' = 'Oms',
  'Sap' = 'Sap'
}

export enum TreeTypeEnum {
  'Products' = 'Products',
  'Parts' = 'Parts'
}
