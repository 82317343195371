
import { computed, defineComponent, ref } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';

import { ConfiguratorItem, ProductItem } from '@/common/services/swagger/index.defs';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { DataType } from '@/products/api/configuration/application/DataType';
import { setupComponent } from '@/products/composables/setupComponent';
import {
  ConfiguratorCommand,
  DetailsCommand,
  PreviewCommand,
} from '@/products/api/runtime/CommandExecutor';
import { attrValue, attrValues, additionalInfo } from '@/common/helpers/productAttributes';
import { IConfiguratorOptions } from '@/products/api/configuration/components/IConfiguratorOptions';
import { IItemsListOptions } from '@/products/api/configuration/components/IItemsListOptions';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Icon from '@/common/components/Icon.vue';
import PisReplacements from '@/common/components/PisReplacements.vue';
import Tooltip from '@/common/components/Tooltip.vue';
import Card from '@/common/components/Card.vue';
import Clickable from '@/common/components/Clickable.vue';
import Image from '@/common/components/Image.vue';

export default defineComponent({
  name: ComponentName.searchList,

  components: {
    InjectStyles,
    Icon,
    PisReplacements,
    Tooltip,
    Card,
    VRuntimeTemplate,
    Clickable,

    Image,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const {
      componentName,
      isReady,
      isWebComponent,
      instance,
      store,
      routeData,
      t,
      widerThanPx,
      emit,
    } = setupComponent(root, props.instanceId, [DataType.Products]);

    const componentOptions = computed(
      () => store.value?.options.components?.itemsList ?? ({} as IItemsListOptions),
    );

    const configuratorOptions = computed(
      () => store.value?.options.components?.configurators as IConfiguratorOptions,
    );

    const currentCIDName = computed(() => {
      const cid = instance.value?.router.routeData?.cid;
      if (cid) {
        const breadcrumbs = instance.value?.store?.data.breadcrumbs?.items;
        if (breadcrumbs) {
          return breadcrumbs[breadcrumbs.length - 1]?.name;
        }
      }
      return undefined;
    });

    const hideIfInitial = computed(() => {
      const history = instance.value?.router.history;
      return store.value?.options.hideInitialSearchResults && history && history.length <= 2;
    });

    const productsItems = computed(() => store.value?.data.products?.items);

    const previewEnabled = computed(
      () => instance.value?.registeredComponents.has(ComponentName.preview) ?? false,
    );

    const configurators = computed(() => store.value?.data.configurators?.items);

    const replacementsVisible = computed(
      () => store.value?.options.components?.common?.hideReplacements != true,
    );

    const showActionTemplate = (product: ProductItem) => {
      return (
        componentOptions.value.actionTemplate &&
        (componentOptions.value.showActionTemplateIfInactive || !product?.inactive)
      );
    };

    const preview = async (product: ProductItem) => {
      try {
        await instance.value?.execute(new PreviewCommand({ productId: product.productId }));
      } catch (error) {
        // Ignore Error
      }
    };

    const showConfigurator = async (configurator: ConfiguratorItem) => {
      try {
        await instance.value?.execute(new ConfiguratorCommand(configurator));
      } catch (error) {
        // Ignore Error
      }
    };

    const details = async (product: ProductItem) => {
      if (product?.inactive) return;

      if (routeData.value && product) {
        try {
          await instance.value?.execute(new DetailsCommand(product));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    return {
      root,
      instance,
      componentName,
      componentOptions,
      isReady,
      isWebComponent,
      store,
      hideIfInitial,
      productsItems,
      previewEnabled,
      configurators,
      configuratorOptions,
      replacementsVisible,
      currentCIDName,
      showActionTemplate,
      showConfigurator,
      t,
      widerThanPx,
      attrValue,
      attrValues,
      details,
      preview,
      additionalInfo,
      emit,
    };
  },
});
