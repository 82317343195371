import {
  CatalogProductAddAction,
  CatalogProductAddItem,
  Catalog,
  CatalogTypeEnum,
  Owner,
  OwnerTypeEnum,
  CustomFieldItem,
  CatalogAppSettings,
  ClientInfo,
  CatalogAppSpecificSettings,
  ActionResult,
  ActionInfo,
  ConfigurationAddAction,
  ConfigurationActionResult,
  Configuration,
  ConfigurationGroup,
  ConfigurationElement,
  QuantityAddAction,
  QuantityAddItem,
  QuantityActionResult,
  Quantity,
  ConfigurationId,
  CatalogProductAutocompleteQuery,
  CatalogProductSearchSettings,
  TreeTypeEnum,
  Alias,
  FilterSettings,
  FilterAttributeValue,
  CatalogSearchSettings,
  CatalogSearchTypeEnum,
  PagerSettings,
  AutocompleteSearchResult,
  AutocompleteItem,
  Image,
  AutocompleteCategoryItem,
  BreadcrumbItem,
  QuantityCopyAction,
  QuantityCopyToNewAction,
  CatalogProductCountQuery,
  CountSearchResult,
  CatalogProductDeleteAction,
  CatalogProductDeleteItem,
  ConfigurationDeleteAction,
  QuantityDeleteAction,
  QuantityDeleteItem,
  CatalogProductFiltersQuery,
  CatalogProductFilterSearchSettings,
  FilterValueSearchResult,
  FilterValueItem,
  FilterValue,
  ConfigurationGetQuery,
  ConfigurationItem,
  CatalogProductSearchQuery,
  CatalogProductSearchTypeEnum,
  CatalogProductSortSettings,
  CatalogProductSortFieldEnum,
  SortDirectionEnum,
  CatalogProductSearchResult,
  ClassificationsSearchResult,
  ClassificationAdditionalInfo,
  ClassificationAdditionalInfoTypeEnum,
  ClassificationItem,
  CatalogProductsSearchResult,
  CatalogProductItem,
  AliasItem,
  CatalogDetail,
  QuantityItem,
  CatalogProductTypeEnum,
  ProductItem,
  ProductReplacement,
  Link,
  LinkTypeEnum,
  ProductAdditionalInfo,
  ProductAdditionalInfoTypeEnum,
  ProductItemClassifications,
  FilterSearchResult,
  FilterItem,
  BreadcrumbsSearchResult,
  CatalogProductUpdateAction,
  CatalogProductUpdateItem,
  ConfigurationUpdateAction,
  QuantityUpdateAction,
  QuantityUpdateItem,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class CatalogsProductsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   * Create products
   */
  static add(
    params: {
      /** requestBody */
      body?: CatalogProductAddAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/Add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create product configuration
   */
  static addConfiguration(
    params: {
      /** requestBody */
      body?: ConfigurationAddAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConfigurationActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/AddConfiguration';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create product quantity
   */
  static addQuantity(
    params: {
      /** requestBody */
      body?: QuantityAddAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuantityActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/AddQuantity';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Autocomplete products
   */
  static autocomplete(
    params: {
      /** requestBody */
      body?: CatalogProductAutocompleteQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AutocompleteSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/Autocomplete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Copy quantity
   */
  static copyQuantity(
    params: {
      /** requestBody */
      body?: QuantityCopyAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/CopyQuantity';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Copy quantity to new catalog
   */
  static copyQuantityToNew(
    params: {
      /** requestBody */
      body?: QuantityCopyToNewAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/CopyQuantityToNew';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve a product count
   */
  static count(
    params: {
      /** requestBody */
      body?: CatalogProductCountQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CountSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/Count';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete products
   */
  static delete(
    params: {
      /** requestBody */
      body?: CatalogProductDeleteAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete product configuration
   */
  static deleteConfiguration(
    params: {
      /** requestBody */
      body?: ConfigurationDeleteAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/DeleteConfiguration';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete product quantity
   */
  static deleteQuantity(
    params: {
      /** requestBody */
      body?: QuantityDeleteAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/DeleteQuantity';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve product filters
   */
  static filters(
    params: {
      /** requestBody */
      body?: CatalogProductFiltersQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FilterValueSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/Filters';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve a configuration
   */
  static getConfiguration(
    params: {
      /** requestBody */
      body?: ConfigurationGetQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConfigurationItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/GetConfiguration';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search products
   */
  static search(
    params: {
      /** requestBody */
      body?: CatalogProductSearchQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogProductSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/Search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update products
   */
  static update(
    params: {
      /** requestBody */
      body?: CatalogProductUpdateAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/Update';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update product configuration
   */
  static updateConfiguration(
    params: {
      /** requestBody */
      body?: ConfigurationUpdateAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConfigurationActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/UpdateConfiguration';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update product quantity
   */
  static updateQuantity(
    params: {
      /** requestBody */
      body?: QuantityUpdateAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuantityActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Products/UpdateQuantity';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
