import {
  AuthorizeCatalogUser,
  CatalogUser,
  LoginResult,
  CatalogAddAction,
  Catalog,
  CatalogTypeEnum,
  Owner,
  OwnerTypeEnum,
  CatalogAppSettings,
  ClientInfo,
  CatalogAppSpecificSettings,
  CatalogActionResult,
  ActionInfo,
  CatalogAutocompleteQuery,
  CatalogSearchSettings,
  PagerSettings,
  CatalogAutocompleteSearchResult,
  CatalogCopyAction,
  ActionResult,
  CatalogCopyToNewAction,
  CatalogAction,
  CatalogExportAction,
  CatalogExportPriceSettings,
  CatalogExportActionResult,
  CatalogExportResultTypeEnum,
  CatalogGetQuery,
  CatalogItem,
  CatalogImportAction,
  CatalogOptionsQuery,
  CatalogOptionsResult,
  CatalogOption,
  CatalogSearchQuery,
  CatalogSortSettings,
  CatalogSortFieldEnum,
  SortDirectionEnum,
  CatalogSearchResult,
  CatalogUpdateAction,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class CatalogsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   * Acquire token for catalog client
   */
  static acquireTokenForClient(
    params: {
      /** requestBody */
      body?: AuthorizeCatalogUser;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/AcquireTokenForClient';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create catalog
   */
  static add(
    params: {
      /** requestBody */
      body?: CatalogAddAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Autocomplete catalogs
   */
  static autocomplete(
    params: {
      /** requestBody */
      body?: CatalogAutocompleteQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogAutocompleteSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Autocomplete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Copy catalog
   */
  static copy(
    params: {
      /** requestBody */
      body?: CatalogCopyAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Copy';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Copy to new catalog
   */
  static copyToNew(
    params: {
      /** requestBody */
      body?: CatalogCopyToNewAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/CopyToNew';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete catalog
   */
  static delete(
    params: {
      /** requestBody */
      body?: CatalogAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Download Excel file
   */
  static download(
    params: {
      /** Guid of requested file */
      guid?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { guid: params['guid'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Export catalog
   */
  static export(
    params: {
      /** requestBody */
      body?: CatalogExportAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogExportActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve a catalog
   */
  static get(
    params: {
      /** requestBody */
      body?: CatalogGetQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Get';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Import catalog
   */
  static import(
    params: {
      /** requestBody */
      body?: CatalogImportAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve options
   */
  static options(
    params: {
      /** requestBody */
      body?: CatalogOptionsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogOptionsResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Options';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search catalogs
   */
  static search(
    params: {
      /** requestBody */
      body?: CatalogSearchQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update catalog
   */
  static update(
    params: {
      /** requestBody */
      body?: CatalogUpdateAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CatalogActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Update';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
