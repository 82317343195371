
import { Ref, computed, defineComponent, onMounted, ref, watch } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import { attrValue, attrValues, additionalInfo } from '@/common/helpers/productAttributes';
import {
  CatalogOptionsResult,
  CatalogProductItem,
  CatalogProductTypeEnum,
  ProductItem,
} from '@/common/services/swagger/index.defs';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import {
  CatalogGetOptionsCommand,
  DetailsCommand,
  PreviewCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import InjectStyles from '@/common/components/InjectStyles.vue';
import Icon from '@/common/components/Icon.vue';
import Clickable from '@/common/components/Clickable.vue';
import { IAliasesTableOptions } from '@/catalogs/api/configuration/components/IAliasesTableOptions';
import useCatalogActions from '@/catalogs/composables/useCatalogActions';
import { Instance } from '@/catalogs/api/Instance';
import { IMenuItem } from '@/catalogs/api/configuration/components/IExportMenuOptions';
import { AddNotificationCommand } from '@/catalogs/api/runtime/CommandExecutor';
import { NotificationType } from '@/common/api/runtime/INotification';

export default defineComponent({
  name: ComponentName.aliasesTable,

  components: {
    VRuntimeTemplate,
    InjectStyles,
    Icon,
    Clickable,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const aliasesEditableOption = ref(false);
    const catalogProductType = CatalogProductTypeEnum;

    const { componentName, isReady, isWebComponent, instance, store, routeData, emit, t } =
      setupComponent(root, props.instanceId, [DataType.Products]);

    const { onAliasItemMenuAction } = useCatalogActions(instance as Ref<Instance>, t);

    const productsItems = computed(() => store.value?.data.products?.items);

    const previewEnabled = computed(
      () => instance.value?.registeredComponents.has(ComponentName.preview) ?? false,
    );

    const componentOptions = computed(
      () => store.value?.options.components?.aliasesTable ?? ({} as IAliasesTableOptions),
    );

    const columns = computed(() => {
      return componentOptions.value.columns;
    });

    const previewByProductId = async (productId: string): Promise<void> => {
      try {
        await instance.value?.execute(new PreviewCommand({ productId }));
      } catch (error) {
        // Ignore Error
      }
    };

    const showingAllCatalogs = computed(() => {
      return !routeData.value?.products.catalogId;
    });

    const details = (product: CatalogProductItem) => {
      if (product.inactive) {
        return;
      }
      try {
        return instance.value?.execute(new DetailsCommand(product as ProductItem));
      } catch (error) {
        // Ignore error
      }
    };

    const aliasItemMenu = computed(() => store.value?.options.components?.actions?.aliasItemMenu);

    const aliasItemMenuItems = (alias: CatalogProductItem): IMenuItem<CatalogProductItem>[] =>
      (aliasItemMenu.value?.items || []).filter((item: IMenuItem<CatalogProductItem>) => {
        if (typeof item.visible === 'function') {
          return item.visible(alias);
        }
        return item.visible;
      });

    const actionsEnabled = computed(() => {
      if (
        aliasesEditableOption.value &&
        instance.value?.registeredComponents.has(ComponentName.aliasActionModals) &&
        aliasItemMenu.value?.visible
      ) {
        return true;
      }
      return false;
    });

    const isDisabled = (menuItem: IMenuItem<CatalogProductItem>, alias: CatalogProductItem) =>
      typeof menuItem.disabled === 'function' ? menuItem.disabled(alias) : menuItem.disabled;

    const onInit = async () => {
      try {
        const catalogOptionsResult: CatalogOptionsResult | undefined =
          (await instance.value?.execute(new CatalogGetOptionsCommand())) as CatalogOptionsResult;
        if (!catalogOptionsResult) {
          throw new Error(t('CatalogOptionsLoadingError'));
        }
        aliasesEditableOption.value = catalogOptionsResult.aliasesEditable || false;
      } catch (error) {
        await instance.value?.execute(
          new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
        );
      }
    };

    onMounted(() => {
      if (!instance.value?.isInitialized()) {
        const unwatch = watch(
          () => instance.value?.isInitialized(),
          (isInitialized: boolean | undefined) => {
            if (!isInitialized) {
              return;
            }
            unwatch();
            return onInit();
          },
        );
        return;
      }
      return onInit();
    });

    return {
      root,
      componentName,
      componentOptions,
      instance,
      isReady,
      isWebComponent,
      store,
      routeData,
      productsItems,
      previewEnabled,
      columns,
      showingAllCatalogs,
      catalogProductType,
      actionsEnabled,
      CatalogProductTypeEnum,
      aliasItemMenuItems,
      details,
      isDisabled,
      t,
      emit,
      previewByProductId,
      attrValue,
      additionalInfo,
      attrValues,
      onAliasItemMenuAction,
    };
  },
});
