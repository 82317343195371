
import { defineComponent, onBeforeUnmount, watch } from 'vue';
import _debounce from 'lodash/debounce';
import _uniqueId from 'lodash/uniqueId';
import PIS from '@/common/api/PIS';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { IOptions } from '../api/runtime/IOptions';
import { DisplayMode, IRouteData, ViewType } from '../api/runtime/IRouteData';
import { Instance } from '../api/Instance';
import { CatalogProductSwitchDisplayModeOption } from '../api/configuration/components/ICatalogProductSwitchOptions';
import { routeMap } from '@/common/helpers/displayModeToRoute';
import { AliasesEnum } from '@/common/services/swagger/index.defs';

const INSTANCE_NAME_PREFIX = 'pisCatalogsInstance_';

export default defineComponent({
  name: ComponentName.instance,

  props: {
    // Allow to configure the APP before displaying it
    // Without this property developers will need to use `instance.init({})` on their own.
    // We use `String` type because adding empty `init` attribute in react apps converts it into `init="true"`.
    init: [Boolean, String],
    instanceId: String,
    baseUrl: String,
    accessToken: String,

    styles: String,

    settingAppCode: String,
    settingCatalogType: String,
    settingLangCode: String,

    // App
    settingAppConfiguredItemAliasesEnabled: Boolean,

    exportPricesEnabled: Boolean,
    exportPricesAgreementId: String,
    exportPricesCustomerId: String,

    evCatalogProductModes: String,
    evCatalogProductMode: String,

    evDisplayMode: String,
    evDisplayModes: String,

    // selection
    selectionVisible: Boolean,
    selectionPersist: Boolean,
    selectionSelectAllVisible: Boolean,
    selectionDeselectAllVisible: Boolean,
    selectionCountVisible: Boolean,
    itemsTableSelectable: Boolean,
    itemsListSelectable: Boolean,
    itemsGridSelectable: Boolean,

    // Component specific
    dashboard: Boolean,
    editing: Object,

    inheritStyles: [Boolean, String],
  },

  setup(props) {
    const instance = PIS.Catalogs.getInstance(
      props.instanceId ?? _uniqueId(INSTANCE_NAME_PREFIX),
    ) as Instance;

    const getInstanceId = () => instance?.id;
    const getInstance = () => instance;
    const requiredPropNames = ['accessToken', 'settingAppCode'];

    const handlePropertyChanged = () => {
      if (instance?.isInitialized()) {
        // TODO: we need to update props, app settings will be watched

        if (props.accessToken) {
          instance.store.options.accessToken = props.accessToken;
        }
        const appOptions = instance.store.options.application;

        if (props.settingLangCode && appOptions) {
          appOptions.langCode = props.settingLangCode;
        }

        return;
      }

      if (props.dashboard) {
        instance.props['dashboard'] = true;
      }

      // display modes - keep order reflecting passed setting
      const displayModesSet = toArray(props.evDisplayModes || undefined);
      const displayModesAllowed = Object.values(DisplayMode);

      let displayModes: DisplayMode[] = [];

      if (displayModesSet && displayModesSet.length) {
        displayModesSet.forEach((dm) => {
          if (displayModesAllowed.includes(DisplayMode[dm])) {
            displayModes.push(DisplayMode[dm]);
          }
        });
      } else {
        displayModes = [...displayModesAllowed];
      }

      const allowedCatalogDisplayModes: CatalogProductSwitchDisplayModeOption[] = Object.values(
        CatalogProductSwitchDisplayModeOption,
      );
      const isAllowedCatalogDisplayMode = (
        item: undefined | string | CatalogProductSwitchDisplayModeOption,
      ): item is CatalogProductSwitchDisplayModeOption =>
        allowedCatalogDisplayModes.includes(item as CatalogProductSwitchDisplayModeOption);
      const givenDisplayModes = toArray<CatalogProductSwitchDisplayModeOption>(
        props.evCatalogProductModes,
      );
      const catalogDisplayModes: CatalogProductSwitchDisplayModeOption[] =
        givenDisplayModes && givenDisplayModes.length
          ? givenDisplayModes.filter((item) => isAllowedCatalogDisplayMode(item))
          : allowedCatalogDisplayModes;
      const catalogDefaultDisplayMode =
        isAllowedCatalogDisplayMode(props.evCatalogProductMode) &&
        catalogDisplayModes.includes(props.evCatalogProductMode)
          ? props.evCatalogProductMode
          : catalogDisplayModes[0];

      const settings = {
        baseUrl: props.baseUrl,
        accessToken: props.accessToken,
        application: {
          appCode: props.settingAppCode,
          langCode: props.settingLangCode,
          catalogType: props.settingCatalogType,
          appSpecific: {
            configuredItemAliasesEnabled: props.settingAppConfiguredItemAliasesEnabled ?? false,
          },
        },
        components: {
          productsSearchToolbar: {
            displayModes,
          },
          catalogsExport: {
            visible: true,
            exportPrices: props.exportPricesEnabled,
            omsAgreementId: props.exportPricesAgreementId,
            omsCustomerId: props.exportPricesCustomerId,
          },
          selection: {
            visible: props.selectionVisible,
            countVisible: props.selectionCountVisible,
            persist: props.selectionPersist,
            selectAllVisible: props.selectionSelectAllVisible,
            deselectAllVisible: props.selectionDeselectAllVisible,
          },
          itemsTable: {
            selectable: props.itemsTableSelectable,
          },
          itemsList: {
            selectable: props.itemsListSelectable,
          },
          itemsGrid: {
            selectable: props.itemsGridSelectable,
          },
          actions: props.editing,
          catalogProductSwitch: {
            displayModes: catalogDisplayModes,
            defaultDisplayMode: catalogDefaultDisplayMode,
          },
        },
        styles: props.styles ? JSON.parse(props.styles) : undefined,
        inheritStyles: !!props.inheritStyles,
      } as IOptions;

      const view: ViewType = routeMap[catalogDefaultDisplayMode];
      const route = {
        view,
        catalogs: {},
        products: {
          aliases: view === 'aliases' ? AliasesEnum.All : undefined,
          displayMode:
            props.evDisplayMode && displayModes.includes(props.evDisplayMode as DisplayMode)
              ? DisplayMode[props.evDisplayMode]
              : displayModes[0],
        },
      } as IRouteData;

      (instance as any)['_routeJson'] = JSON.stringify(route, null, 4);
      (instance as any)['_optionsJson'] = JSON.stringify(settings, null, 4);

      if (props.init) {
        const missingProps = requiredPropNames.filter((propName) => !(props as any)[propName]);

        if (missingProps?.length) {
          instance?.logger.log(`Following required props are missing: ${missingProps.join(', ')}`);
        } else {
          instance?.init(settings, route);
        }
      } else {
        instance?.update(settings, route);
      }
    };

    const toArray = <T extends string = string>(val: string | undefined): T[] | undefined => {
      if (val === undefined) {
        return undefined;
      }
      return val.split(',').map((chunk) => chunk?.trim()) as T[];
    };

    onBeforeUnmount(() => {
      instance?.destroy();
    });

    watch(props, _debounce(handlePropertyChanged, 50), { immediate: true });

    return {
      getInstanceId,
      getInstance,
    };
  },
});
