
import { computed, defineComponent, nextTick, ref } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';

import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { attrValue, attrValues } from '@/common/helpers/productAttributes';
import { setupComponent } from '@/catalogs/composables/setupComponent';

import { DetailsCommand, PreviewCommand } from '@/catalogs/api/runtime/CommandExecutor';

import { IItemsGridOptions } from '@/catalogs/api/configuration/components/IItemsGridOptions';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Image from '@/common/components/Image.vue';
import Icon from '@/common/components/Icon.vue';
import ItemCatalogsDetailsModal from './components/ItemCatalogsDetailsModal.vue';
import Clickable from '@/common/components/Clickable.vue';
import {
  CatalogProductTypeEnum,
  CatalogProductItem,
  ProductItem,
} from '@/common/services/swagger/index.defs';

export default defineComponent({
  name: ComponentName.searchProductsGrid,

  components: {
    InjectStyles,
    Image,
    Clickable,
    Icon,
    VRuntimeTemplate,
    ItemCatalogsDetailsModal,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const catalogProductType = CatalogProductTypeEnum;

    const {
      componentName,
      isWebComponent,
      isReady,
      instance,
      store,
      routeData,
      t,
      widerThan,
      emit,
    } = setupComponent(root, props.instanceId, [DataType.Products]);

    const componentOptions = computed(
      () => store.value?.options.components?.itemsGrid ?? ({} as IItemsGridOptions),
    );

    const currentCIDName = computed(() => {
      const cid = instance.value?.router.routeData?.products.cid;
      if (cid) {
        const breadcrumbs = instance.value?.store?.data.breadcrumbs?.items;
        if (breadcrumbs) {
          return breadcrumbs[breadcrumbs.length - 1]?.name;
        }
      }
      return undefined;
    });

    const details = async (product: CatalogProductItem): Promise<void> => {
      if (product.inactive) {
        return;
      }
      try {
        if (routeData.value && product) {
          await instance.value?.execute(new DetailsCommand(product as ProductItem));
        }
      } catch (error) {
        //Ignore Error
      }
    };

    const baseProductDetails = async (baseProduct: ProductItem | undefined): Promise<void> => {
      if (!baseProduct) return;
      if (baseProduct.inactive) {
        return;
      }
      try {
        await instance.value?.execute(new DetailsCommand(baseProduct));
      } catch (error) {
        //Ignore Error
      }
    };

    const productsItems = computed(() => store.value?.data.products?.items);

    const previewEnabled = computed(
      () => instance.value?.registeredComponents.has(ComponentName.preview) ?? false,
    );

    const isPreviewDisabled = (item: CatalogProductItem) =>
      (item.baseProduct && item.baseProduct.inactive) || item.inactive;

    const previewByProductId = async (productId: string): Promise<void> => {
      try {
        await instance.value?.execute(new PreviewCommand({ productId }));
      } catch (error) {
        // Ignore Error
      }
    };

    const selectedItem = ref();
    const catalogsModalVisible = ref(false);

    const toggleItemDetails = (product: CatalogProductItem) => {
      selectedItem.value = product;
      catalogsModalVisible.value = false;
      nextTick(() => {
        catalogsModalVisible.value = true;
      });
    };

    const getItemImage = (item: CatalogProductItem): string | undefined => {
      if (item.baseProduct) {
        return (item.baseProduct.images || [])[0]?.url;
      }
      return (item.images || [])[0]?.url;
    };

    const showingAllCatalogs = computed(() => {
      return !routeData.value?.products.catalogId;
    });

    return {
      root,
      instance,
      componentName,
      componentOptions,
      isWebComponent,
      isReady,
      store,
      productsItems,
      previewEnabled,
      currentCIDName,
      selectedItem,
      catalogsModalVisible,
      showingAllCatalogs,
      catalogProductType,
      CatalogProductTypeEnum,
      previewByProductId,
      baseProductDetails,
      toggleItemDetails,
      isPreviewDisabled,
      getItemImage,
      widerThan,
      attrValue,
      attrValues,
      details,
      emit,
      t,
    };
  },
});
