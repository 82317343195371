
import { computed, defineComponent, ref } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import { attrValue, attrValues, additionalInfo } from '@/common/helpers/productAttributes';
import {
  CatalogProductItem,
  CatalogProductTypeEnum,
  ProductItem,
} from '@/common/services/swagger/index.defs';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { DetailsCommand, PreviewCommand } from '@/catalogs/api/runtime/CommandExecutor';
import { setupComponent } from '@/catalogs/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Icon from '@/common/components/Icon.vue';
import Clickable from '@/common/components/Clickable.vue';
import { IItemsTableOptions } from '@/catalogs/api/configuration/components/IItemsTableOptions';
import ItemCatalogsDetails from './components/ItemCatalogsDetails.vue';

export default defineComponent({
  name: ComponentName.searchProductsTable,

  components: {
    VRuntimeTemplate,
    InjectStyles,
    Icon,
    Clickable,
    ItemCatalogsDetails,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const catalogProductType = CatalogProductTypeEnum;

    const { componentName, isReady, isWebComponent, instance, store, routeData, emit, t } =
      setupComponent(root, props.instanceId, [DataType.Products]);

    const productsItems = computed<CatalogProductItem[]>(
      () => store.value?.data.products?.items || [],
    );

    const previewEnabled = computed(
      () => instance.value?.registeredComponents.has(ComponentName.preview) ?? false,
    );

    const isPreviewDisabled = (item: CatalogProductItem) =>
      (item.baseProduct && item.baseProduct.inactive) || item.inactive;

    const componentOptions = computed(
      () => store.value?.options.components?.itemsTable ?? ({} as IItemsTableOptions),
    );

    const columns = computed(() => {
      return componentOptions.value.columns;
    });

    const details = async (product: CatalogProductItem): Promise<void> => {
      if (product.inactive) {
        return;
      }
      try {
        if (routeData.value && product) {
          await instance.value?.execute(new DetailsCommand(product as ProductItem));
        }
      } catch (error) {
        //Ignore Error
      }
    };

    const baseProductDetails = async (baseProduct: ProductItem | undefined): Promise<void> => {
      if (!baseProduct) return;
      if (baseProduct.inactive) {
        return;
      }
      try {
        await instance.value?.execute(new DetailsCommand(baseProduct));
      } catch (error) {
        //Ignore Error
      }
    };

    const previewByProductId = async (productId: string): Promise<void> => {
      try {
        await instance.value?.execute(new PreviewCommand({ productId }));
      } catch (error) {
        // Ignore Error
      }
    };

    const toggleItemDetails = (product: CatalogProductItem) => {
      product['expanded'] = !product['expanded'];
    };

    const showingAllCatalogs = computed(() => {
      return !routeData.value?.products.catalogId;
    });

    return {
      root,
      componentName,
      componentOptions,
      instance,
      isReady,
      isWebComponent,
      store,
      routeData,
      productsItems,
      previewEnabled,
      columns,
      showingAllCatalogs,
      catalogProductType,
      CatalogProductTypeEnum,
      t,
      emit,
      details,
      previewByProductId,
      attrValue,
      additionalInfo,
      attrValues,
      isPreviewDisabled,
      toggleItemDetails,
      baseProductDetails,
    };
  },
});
